/* common styles for app */

body {
  display: block;
  margin: 0;
  height: 100%;
  width: 100%;
}

.backIcon {
  color: white;
  height: 35px;
  line-height: 25px;
  font-size: 15px;
}

@font-face {
  font-display: optional;
  font-family: 'Roboto-Regular';
  src: url('../../fonts/Roboto/Roboto-Regular.woff') format('woff');
}

@font-face {
  font-display: optional;
  font-family: 'Open Sans';
  src: url('../../fonts/OpenSans/OpenSans-Regular.woff') format('woff');
}

.inline_block {
  display: inline-block;
}

.inline_flex {
  display: inline-flex;
}

.list_style_none {
  list-style: none;
}

.margin_top_15 {
  margin-top: 15px;
}

.cursor {
  cursor: pointer;
}

.align_center {
  text-align: center;
}

.padding-5 {
  padding: 5px;
}

.overflow_Y {
  overflow-y: auto;
  max-height: 100%;
}

.hide {
  display: none !important;
}

.show {
  display: block !important;
}

.m-left-10 {
  margin-left: 10px;
}

.parent_height {
  display: initial;
}

.child_height {
  height: 75%;
  margin: 15px;
}

.height-10per {
  height: 10%;
}

.height-30per {
  height: 27%;
}

.height-70per {
  height: 70%;
}

.height-80per {
  height: 70%;
}

.height-90per {
  height: 90%;
}

.height-100per {
  height: 100%;
}

.padding-15 {
  padding: 15px;
  touch-action: manipulation;
}

.padding_top_20 {
  padding: 20px;
}

.padding-top-0 {
  padding-top: 0px;
}

.padding-top-5 {
  padding-top: 5px;
  touch-action: manipulation;
}

.padding-10 {
  padding: 10px;
}

.m-auto {
  margin: auto;
}

.cursor {
  cursor: pointer;
}

.chart_tooltip {
  font-size: 16px;
}

.color_dark_gray {
  color: rgba(17, 17, 17, 0.67);
  padding-left: 15px;
  font-size: 14px;
  margin-bottom: 0;
}

.green {
  color:green;
}

.purple {
  color:#427dff;
}

.grey-indent {
  background: #f5f6f5;
  margin: 0 0 10px 10px;
  border-left: 2.5px solid #427dff;
  padding: 0.5% 1% 0.5% 3%;
}

.indent {
  padding-left: 15%;
}

.sm-indent {
  padding-left: 7%;
}

.xs-indent {
  padding-left: 3.5%;
}

.grey {
  background: #f5f6f5;
}

.grey-indent h3 {
  font-weight: bolder;
  color: #427dff;
  padding-left: 2%;
}

/* make ares scroll able  */

.scroll-view {
  overflow: auto;
  padding: 10px;
  height: 100%;
}

.scroll {
  overflow: auto;
  margin-bottom: 10px;
}

.padding_left_15 {
  padding-left: 15px;
}

/* CSS Style MS*/

.hidden {
  display: none;
}

/* view  modal */

.visible {
  display: block;
}

.message_detail_conv {
  font-size: 1.3em;
  letter-spacing: -1.0px;
}

.result-string {
  text-align: left;
  color: white;
  margin: auto;
  padding: 10px;
  font-size: 1.2em;
}

.mob-header {
  min-height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
  margin: 0px;
}

.message_detail {
  text-align: center;
  color: white;
  margin: auto;
  font-size: 1.5em;
  padding: 10px;
  text-shadow: 0px 5px 8px rgba(0, 0, 0, 0.3);
  width: 100%;
  word-wrap: break-word;
}

.message_detail.conversation {
  font-size: 1.9em;
}

.message_detail.conversation-ipad {
  font-size: 1.9em;
  min-height: 370px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 0px;
  margin: 0px;
}

/* Pages details */

.white {
  color: white;
}

.pages-details {
  font-size: 1.0em;
  text-align: center;
}

.pages-details .active-page {
  color: green;
}

/*MS Style ends here*/

/********************List Syles***********************/

button.back-btn1 {
  border-radius: 7px;
  text-align: center;
  background-image: linear-gradient(#41de91 10%, #2f8757);
  color: white;
  border: 1px solid green;
  width: 100%;
  height: 100%;
  margin-top: 4px;
}

button.back-btn {
  border-radius: 30px;
  text-align: center;
  background-color: #e46931;
  color: white;
  box-shadow: 0px 4px 5px 1px rgba(0, 0, 0, 0.2);
  width: 90%;
  height: 42px;
  margin-top: 0px;
  margin-left: 5%;
  border: 0px;
  font-size: 12px;
  padding-top: 4px;
}

/*-------------tooltip---------------  */

.tooltip {
  position: relative;
  display: inline-flex;
  padding: 10px;
  height: 100%;
  margin: auto;
}

.tooltip::-webkit-scrollbar-track {
  background: rgba(254, 254, 254);
}

.tooltip::-webkit-scrollbar-thumb {
  background: rgba(254, 254, 254);
  border-radius: 10px;
}

.tooltiptext {
  visibility: hidden;
  font-size: 15px;
  color: #fff;
  z-index: 9999;
  width: 250px;
  background-color: #555;
  padding: 8px;
  border-radius: 10px;
  display: flex;
  position: absolute;
  left: 67%;
  top: -35%;
  margin-top: 7px;
  height: auto;
  max-height: 140px;
  min-height: 55px;
  overflow-y:scroll;
}

.pullright {
  float: right;
}

.padding-left-30 {
  padding-left: 30px !important;
}

.clickable {
  cursor: pointer;
}

@media (min-width:700px) {
  .tooltiptext {
    width: 300px;
  }
}

.tooltiptext span {
  width: 100%;
  display: block;
  padding: 1px 4px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.tooltip .tooltiptext::before {
  content: "";
  position: absolute;
  top: 20px;
  left: -10px;
  height: 1px;
  width: 1px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent #555 transparent transparent;
}

@media (min-width: 768px) {
  .tooltiptext {
    top: -15%;
  }
  .tooltip .tooltiptext::before {
    top: 25px;
  }
}

@media (min-width: 992px) {
  .tooltiptext {
    height: auto;
  }
}

/*-------------App Popup models CSS START-------------*/

.model-container {
  display: none;
  position: fixed;
  z-index: 9999999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  /* overflow: auto; */
  background-color: white;
}

/* view  modal */

.model-container.visible {
  display: block;
}

.model-container.hidden {
  display: none;
}

.model-container .close-btn-div {
  width: 100%;
  height: 50px;
  display: inline-flex;
  cursor: pointer;
  background: #4ab77d;
}

.model-container img.close-btn {
  float: right;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: 10px;
}

/*-------------App Popup models CSS END-------------*/

/*Bar chart CSS */

ul.bar-no-style {
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  margin-top: 30px;
}

ul.bar-no-style>li {
  text-align: left;
  margin: 5px 0px;
  float: left;
}

ul.bar-no-style>li>div {
  display: block;
  padding: 5px;
  text-decoration: none;
}

/*-------------Main Toolbar-------------*/

/*htmlPages link coloring*/

a>strong {
  background: linear-gradient(to left, #396571 0%, #427dff 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
  font-weight: bolder;
  font-size: 16px;
}

a:link, a:visited, a:active {
  cursor: pointer;
}

.gradient {
  background: linear-gradient(to right, #396571 0%, #427dff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bolder;
  float: left;
  display: inline;
  margin-right: 5px;
}

.gradient:hover {
  cursor: pointer;
}

.margin-mod {
  margin: auto 10% 5% 10%;
}

.center {
  text-align: center;
}

.autoloan-dashboard .input-control {
  width: 100%;
  height: 60px;
  font-size: 40px;
  text-align: right;
  background-color: transparent;
  background-image: none;
  border: none;
  padding-right: 5px
}

.autoloan-dashboard .no-error {
  color: #388f65;
}

.autoloan-dashboard .error {
  color: red;
}

.autoloan-dashboard .input-control-text {
  width: 100%;
  height: 40px;
  font-size: 24px;
  text-align: right;
  border-radius: 10px;
  background-image: none;
  border: none;
  padding-right: 15px;
  background-color: transparent
}

.autoloan-dashboard .dropdown {
  position: relative;
  text-align: right;
}

.autoloan-dashboard .drop-section {
  font-size: 32px;
  color: #396571;
  cursor: pointer;
  padding-right: 10px;
  padding-top: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 360px;
}

.autoloan-dashboard .dropdown-menu {
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 1000;
  display: none;
  float: left;
  width: 180px;
  max-width: 100%;
  /* overflow-x: hidden; */
  /* overflow-y: scroll; */
  /* max-height: 222px; */
  padding: 5px 0;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, .15);
  border-radius: 4px;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
}

.autoloan-dashboard .dropdown-menu .menu-sub {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: #333;
  white-space: nowrap;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.autoloan-dashboard .dropdown:hover>.dropdown-menu {
  display: block;
  width: auto;
}

.autoloan-dashboard .dropdown-menu .menu-sub:focus, .autoloan-dashboard .dropdown-menu .menu-sub:hover {
  color: #000000;
  text-decoration: none;
  background-color: #eee;
}

.autoloan-dashboard .datepicker-input {
  width: 100%;
  font-size: 30px;
  text-align: right;
  background-color: transparent;
  background-image: none;
  border: none;
  padding-top: 9px;
}

.autoloan-dashboard {
  display: flex !important;
}

.autoloan-dashboard .section-first {
  margin: auto;
}

.autoloan-dashboard .section-second {
  margin: auto 0 auto auto;
}

.autoloan-dashboard .section-second .dropdown {
  float: right;
}

.main-dashboard .simple-line {
  border: 0.70px solid lightgray;
}

.widget_One .questions-list li:last-child .autoloan-dashboard .section-second .dropdown-menu {
  width: auto;
  /* max-width: 430px; */
  /* max-height: 70px;
  min-height: 70px; */
  /* overflow-y: auto; */
  margin-top: -128px;
  /* position: static; */
  margin-left: -40px;
}

.widget_One .questions-list li:last-child .autoloan-dashboard .section-second .dropdown-menu:before {
  position: absolute;
  top: 80px;
  right: 17px;
  display: inline-block;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #e0e0e0;
  border-left: 7px solid transparent;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  border-bottom-color: rgba(0, 0, 0, 0.15);
  content: "";
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.widget_One .questions-list li:last-child .autoloan-dashboard .section-second .dropdown-menu:after {
  position: absolute;
  top: 80px;
  right: 18px;
  display: inline-block;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #ffffff;
  border-left: 6px solid transparent;
  content: "";
  transform: rotate(180deg);
}

.autoloan-dashboard .dropdown-menu .scrollsection {
  overflow: hidden;
  overflow-y: auto;
  max-height: 70px;
  min-height: 70px;
}

.widget_One .questions-list li:last-child .autoloan-dashboard .section-second .dropdown-menu .scrollsection {
  overflow: hidden;
  overflow-y: auto;
  max-height: 70px;
  min-height: 70px;
}

.input-control-converion {
  outline: 0;
  width: 100%;
  border-radius: 5px;
  border: 0;
  height: 30px;
  padding: 10px;
  margin: 0px;
  font-size: 25px;
}

.swipe-icon {
  margin: auto;
  background: url('../../ImagesWeb/shuffle.png');
  height: 50px;
  width: 50px;
  background-size: 45px;
  background-repeat: no-repeat;
}

.text-input-mob {
  width: 100%;
  padding: 6px;
  border: none;
  border-radius: 15px;
  font-size: 20px;
  color: black;
}

.done-button-container {
  margin-top: 60px;
  bottom: 0;
}

.text-input-error {
  color: red;
  font-size: 1.4em;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  height: 22px;
}

.fields-content-container {
  margin-top: 6%;
}

.done-button-container .green button {
  background-image: linear-gradient(#41de91 10%, #2f8757);
  color: white;
  font-size: 20px;
  padding: 6px;
  font-family: initial;
  font-weight: bold;
}

.done-button-container .width_100per {
  width: 80%;
}

.question_content .dropdown {
  position: relative;
  display: inline-block;
}

.question_content .dropdown-content {
  display: none;
  position: absolute;
  text-align: left;
  width: 100%;
  background-color: #ffffff;
  min-width: 300px;
  margin-top: 20px;
  top: 10px;
  border: 1px solid #ddd;
  border-radius: 6px;
  cursor: pointer;
  max-height: 135px;
  font-size: 16px;
  z-index: 1;
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

.question_content .dropdown-content div {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.question_content .dropdown-content div:hover {
  background-color: #eeeeee;
}

.question_content:hover .dropdown-content {
  display: block;
}

/* Disable pointer events when selector is open */

.events-none {
  pointer-events: none;
}

.height-selector {
  display: inline-flex;
  align-content: center;
  align-items: center;
  font-size: 12px;
}

.grid-title {
  height: auto;
  /* padding: 10px; */
  color: black;
  width: 100%;
  text-align: center;
}

.share-this-popover ul {
  list-style: none;
  background: #ececec;
  padding-left: 5px;
  height: 55px;
  display: flex;
  width: 235px;
  border-radius: 10px;
  padding-top: 6px;
  position: relative;
  top: 25px;
  box-shadow: 2px 2px 10px 1px #b9d9f5;
}

a:focus, a:hover {
  color: #8ec1ef
}

a {
  color: #eee;
}

.share-this-popover svg {
  width: 45px;
  height: 46px;
}

/**************picker-container**********************/

.picker-section .picker-container .picker-column .picker-scroller {
  /* transform: translate3d(0px, 40px, 0px) !important; */
  position: relative;
  bottom: 0;
}

/**************picker-container**********************/

.widget_Two ul.result-list li.active .result-list-item-emboss {
  border: 2.5px solid #396571;
}

/*******contactus-modal****************/

.contactus-modal .title-name {
  background: #3f3f41;
  padding: 20px 20px 16px;
  height: 55px;
}

.contactus-modal .title-name h2 {
  color: #ffffff;
  text-align: left;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.2;
  white-space: normal;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: normal;
  text-transform: capitalize;
}

.contactus-modal .body-part {
  background: #3f3f41;
  text-align: left;
  font-size: 14px;
  color: #ffffff;
  white-space: normal;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: normal;
  padding: 0 20px 20px;
}

.contactus-modal {
  max-width: 506px;
  margin: 0 auto;
}

.contactus-modal {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.contactus-modal form input, .contactus-modal form select, .contactus-modal form textarea {
  height: 32px;
  line-height: 32px;
  padding: 0 8px;
  border-radius: 3px;
  vertical-align: middle;
  color: #3f3f41;
  margin-bottom: 15px;
  width: 100%;
}

.contactus-modal form textarea {
  height: 82px;
  resize: none;
}

.margin-top-0 {
  margin-top: 0;
}

.contactus-modal .send-btn {
  color: #ffffff;
  background: #6f6f6f;
  box-sizing: border-box;
  width: 100%;
  text-align: center;
  margin: 0;
  font-weight: 700;
}

.contactus-modal .send-btn:hover {
  background: #7d7d7d;
  color: #ffffff;
}

.contactus-modal .text-format {
  line-height: 1.35;
  margin-top: 0;
}

.contactus-modal .crossicon {
  background: url("../../ImagesWeb/cross-gray.png") no-repeat;
  float: right;
  padding: 8px;
  background-size: 15px;
  transition: transform .2s, opacity .2s, -webkit-transform .2s;
  -webkit-font-smoothing: antialiased;
}

.contactus-modal .crossicon:hover {
  background: url("../../ImagesWeb/cross-icon.png") no-repeat;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  float: right;
  padding: 8px;
  background-size: 15px;
}

.best-matches {
  text-align: center;
  overflow: hidden;
  height: 380px;
}

/*******contactus-modal****************/

/**********About us modal***********/

.aboutus-modal .title-name {
  background: #ffffff;
  padding: 20px 20px 16px;
  height: 65px;
  text-align: left;
}

.aboutus-modal .body-part {
  background: #ffffff;
  text-align: left;
  font-size: 16px;
  color: rgb(0, 0, 0);
  white-space: normal;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: normal;
  padding: 0 25px 25px;
}

.aboutus-modal {
  max-width: 1000px;
  width: auto;
  margin: 0 auto;
}

.aboutus-modal {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.aboutus-modal div:nth-child(2) {
  margin: 5px !important;
}

.aboutus-modal .text-format {
  line-height: 1.5;
  margin-top: 0;
}

.aboutus-modal .crossicon {
  background: url("../../ImagesWeb/cross-gray.png") no-repeat;
  float: right;
  padding: 8px;
  background-size: 15px;
  transition: transform .2s, opacity .2s, -webkit-transform .2s;
  -webkit-font-smoothing: antialiased;
}

.aboutus-modal .crossicon:hover {
  background: url("../../ImagesWeb/cross-icon.png") no-repeat;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  float: right;
  padding: 8px;
  background-size: 15px;
}

/**********About us modal***********/

/* Small Devices, Tablets */

@media only screen and (min-width: 768px) {
  body {
    position: relative !important;
  }
}

@media (max-height: 812px) {
  .height-30per .info-icon {
    bottom: -4px;
  }
  .height-70per .calculate-container {
    position: relative;
    top: 6px;
  }
}

@media (max-height: 667px) {
  .height-30per .slick-slider .slick-list {
    height: 164px !important;
  }
  .height-30per .message_detail {
    top: 56px;
  }
  .height-30per .carousel-wraper .carousel-indicators {
    padding-top: 3px;
  }
  .height-70per .calculate-container {
    top: 0;
  }
}

@media (max-height: 568px) {
  .tooltiptext {
    width: 200px;
  }
  .height-30per .slick-slider .slick-list {
    height: 139px !important;
  }
  .height-30per .message_detail {
    font-size: 1.3em;
    top: 43px;
  }
  .height-30per .info-icon {
    bottom: 0px;
  }
}

/* / category devider */

.hr-text {
  line-height: 1em;
  font-weight: bold;
  position: relative;
  outline: 0;
  border: 0;
  color: black;
  text-align: center;
  height: 1.5em;
  opacity: .7;
}

.hr-text:before {
  content: '';
  background: linear-gradient(to right, transparent, #818078, transparent);
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  height: 2px;
}

.hr-text:after {
  content: attr(data-content);
  position: relative;
  display: inline-block;
  color: black;
  padding: 0 .5em;
  line-height: 1.5em;
  color: #818078;
  background-color: #fcfcfa;
}