
/* DATEPICKER STYLES */
.date-picker-container{
  position: relative;
    display: flex;
    /* border: 1px solid red; */
}


.react-datepicker {
    max-width: 225px;
    background: whitesmoke;
    margin: 0px auto;
    box-shadow: 0 1px 1px #dedede;
    border-radius: 5px;
    position: absolute;
    top: 55px;
    right: 0;
    z-index: 9999;
    user-select: none;
  }
  
  .react-datepicker-controls {
    overflow: hidden;
    text-align: center;
    background: #4bb77d;
    color: #fff;
    height: 35px;
    min-width: 220px;
  }
  
  .react-years{
    height: 150px;
    overflow: auto;
    padding: 2px;
  }

  .react-years button{
    margin-bottom: 2px;
    width: 100%;
    margin-bottom: 2px;
    font-size: 15px;
    background: #fff;
    border: 1px solid whitesmoke;
    padding: 3px;
    cursor: pointer;
    outline: none;
  }

  .react-years button.active{
    background-color: #4bb77d;
    color: white;
  }

  .react-datepicker-previous,
  .react-datepicker-next {
    float: left;
    background: #4bb77d;
    border: none;
    /* padding: 10px 15px; */
    font-size: 17px;
    color: #fff;
    outline: none;
    position: relative;
    z-index: 1;
    cursor: pointer;
  }
  
  .react-datepicker-next {
    float: right;
  }
  
  .react-datepicker-month {
    position: absolute;
    top: 5px;
    left: 0;
    right: 0;
    font-size: 15px;
  }

  .react-datepicker-month.disable {
    color: gray;
  }
  
  .react-datepicker-dates {
    overflow: hidden;
  }
  
  .react-datepicker-date {
    transition: background .2s ease;
    float: left;
    width: 14.285714286%;
    text-align: center;
    font-size: 15px;
    background: #fff;
    border: 1px solid whitesmoke;
    box-sizing: border-box;
    padding: 3px;
    cursor: pointer;
    outline: none;
  }
  
  .react-datepicker-date:hover {
    background: #4bb77d;
    color: white
  }
  
  .react-datepicker-date-disabled {
    color: #ccc;
    cursor: auto;
  }
  
  .react-datepicker-date-disabled:hover {
    background: #fff;
  }
  
  .react-datepicker-date-active {
    background: #6f6f6f;
    color: #fff;
  }
  
  .react-datepicker-date-active:hover {
    background: #6f6f6f;
    color: #fff;
  }
  
  .react-simple-datepicker-input {
    margin-top: 40px;
    padding: 20px 120px 20px 20px;
    font-size: 22px;
    border: 1px solid #ccc;
    border-radius: 5px;
    color: #3f3f41;
    cursor: pointer;
  }
  .widget_One .questions-list li:last-child .autoloan-dashboard .section-second .react-datepicker{
    top: -192px;
  }