
.h100-per{
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.top-nav-2{
  margin-top: -65px;
}


.bottom-nav-2{
  margin-bottom: -70px;
  border-radius: 0% 0% 15px 15px;
  touch-action: manipulation;
}

.panel-background {
background: url('../../Images/main-bg-opt.png');
background-size: 471px;
display: inline-flex;
}

.answer-detail {
color: white;
font-size: 15px;
}

.margin_bottom_top_1 > div {
color: white;
text-align: center;
}

.widget_One,
.widget_Two {
  font-size: 16px;
  overflow: auto;
  height: 100%;
  background: url("../../Images/main-bg-opt.png");
  background-size: 471px 521px;
}

.widget_One .slider-list{
height: 380px !important;
overflow-y: auto;
overflow-x: hidden;
}

.widget_One{
  overflow: visible;
}

.widget_One .slider {
  overflow: visible;
}

.widget_One .slider-frame {
  overflow: visible !important;
  touch-action: manipulation;
}
.previous-img{
  position: inherit !important;
}

.widget_One .slider ul {
  overflow: visible;
}

.height-70per .widget_One .slider-list{
height: 366px !important;
}
.widget_Two{
padding-left: 10px;
padding-right: 10px;
}

.widget_One ul,
.widget_Two ul {
padding: 0;
}

.widget_One li,
.widget_Two li {
  border-radius: 4px;
  border: 2px solid rgba(0, 0, 0, 0);
  color: #396571;
  list-style: none;
}

.widget_One li.disabled,
.widget_Two li :disabled {
background-color: #b6bec6;
border: 1px solid #949799;
}

.back-gradient {
padding-top: 6px;
background-image: radial-gradient(
  at top,
  rgba(0, 0, 0, 0.2) 40%,
  rgba(0, 0, 0, 0),
  rgba(0, 0, 0, 0)
);
}

.answer_value {
font-size: 1.8em;
font-weight: bold;
text-shadow: 0px 5px 6px rgba(0, 0, 0, 0.3);
}

.answer_value_conversion {
font-size: 1.3em;
font-weight: bold;
text-shadow: 0px 5px 6px rgba(0, 0, 0, 0.3);
}
.answer_title {
text-shadow: 0px 5px 6px rgba(0, 0, 0, 0.3);
font-size: 16px;
font-family: "SF-Pro-Display-SemiBold";
}
.answer_title1 {
text-shadow: 0px 5px 6px rgba(0, 0, 0, 0.3);
font-size: 20px;
font-family: "SF-Pro-Display-SemiBold";
}

.carousel_widget {
font-size: 16px;
overflow: auto;
height: 100%;
background: url("../../Images/main-bg-opt.png");
background-size: 471px 521px;
}

hr.box-shadow {
width: 140px;
background-color: #2f8757;
padding: 0px;
margin-bottom: 0px;
height: 2px;
border: none;
margin-top: 4px;
box-shadow: 0px 6px 42px -1px rgba(0, 0, 0, 1);
}

.carousel_widget ul {
padding: 0;
}

.carousel_widget li {
border-radius: 4px;
border: 1px solid lightgray;
background-color: #fff;
color: black;
margin: 10px;
list-style: none;
box-shadow: 1px 1px 1px 1px #b7b2b2;
}

.carousel_widget li img {
margin: 20px;
}

.carousel_widget li.disabled {
background-color: #b6bec6;
border: 1px solid #949799;
}

.float-left{
float: left;
width: 100%;
display: inline-flex;
}

.width-40p{
width:40%
}

.width-60p{
width:60%
}


.widget-10-per
{
  padding-top: 52px;
}

.widget-header-10per{
  height: 52px;
  margin-top: -52px;
}







.fa-chevron-right,
.fa-chevron-left {
color: #7c8e95 !important;
width: 43px !important;
height: 45px !important;
position: relative;
top: -12px;
}

.fa-chevron-right:before,
.fa-chevron-left:before {
font-size: 25px !important;
}

/* Header container */

.relative {
position: relative;
}

/* header information for error  */

.error-message {
color: white;
text-align: center;
padding: 10px;
margin-top: 5px;
height: 90%;
}

.error-message .error-title,
.error-details {
color: white;
}

/* error title */

.error-message .error-title {
font-size: 16px;
}

/* error detail */

.error-message .error-details {
font-size: 1.2em;
}

.error-message .error-img {
background: white;
height: 40px;
width: 40px;
border-radius: 50%;
text-align: center;
display: inline-flex;
margin: 10px;
}

.error-img img {
margin: auto;
}


.header-carousel-wraper {
height: 100%;
border: '2px 2px 3px 3px'
}

.inline-class{
display: flex !important;
}  

.inline-contact{
display: contents !important;
width: 100%;
}

.header-data{
color: white;
}

.answer_title_web {
text-shadow: 0px 5px 6px rgba(0, 0, 0, 0.3);
font-size: 22px;
font-family: "SF-Pro-Display-SemiBold";
margin-bottom:-2px; 
}
.answer_value_web{
font-size: 2em;
font-weight: bold;
text-shadow: 0px 5px 6px rgba(0, 0, 0, 0.3);
margin-top:-4px; 
}
.hr-line{
width: 240px;
background-color: #2f8757;
padding: 0px;
margin-bottom: 5px;
height: 2px;
border: none;
margin-top: -3px;
}

.inline-class .second-section, .inline-class .first-section{
margin: auto;
} 
.previous-img{
background:  url('../../Images/button.png') no-repeat;
padding: 17px 40px;
background-size: 80px 46px;
min-width: 80px !important;
color: rgba(0, 0, 0, 0.87);
padding: 8px 16px;
font-size: 0.875rem;
box-sizing: border-box;
min-height: 36px;
transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
font-weight: 500;
font-family: "Roboto", "Helvetica", "Arial", sans-serif;
line-height: 1.4em;
border-radius: 4px;
text-transform: uppercase;
}
.arrow-left-icon{
background:  url('../../Images/leftArrow.png') no-repeat;
padding: 17px 9px;
background-size: 13px 21px;
position: relative;
top: 7px;
left: 1px;
}
.arrow-right-icon{
transform: rotate(180deg);
top: -6px;  
}
.arrow-footer{
display: flex;
flex-direction: row;
align-items: center;
justify-content: flex-start;
height: 71px;
border-top: 1px solid lightgray;
}
.arrow-footer .section-second{
width: 38%;
text-align: center;
}
.arrow-footer .pages-details{
font-size: 20px;
color: #000;
font-weight: bold;
user-select: none;
}

/*-------------dashboard- right- container-------------*/
.main-dashboard .right-container
{
  height: 100%;
  background: #4bb77d; /* Old browsers */
  background: linear-gradient(135deg, #4bb77d 0%,#259984 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  min-height: 412px;
  margin-top: 45px;
  border-radius: 0px 10px 10px 0px;
  box-shadow: 2px 2px 11px 2px rgba(78,181,125,0.52);
  padding: 25px;
}
.main-dashboard .no-padding{
padding: 0;
}
.main-dashboard .white-cross-container{
    width: 45px;
    background: #fff;
    border: 1px solid #000;
    border-radius: 6px 6px 0px 0px;
    border-bottom: 0;
    padding-left: 19px;
    position: absolute;
    right: 31px;
    margin-top: -8px;
    margin-top: -20px;
    height: 21px;
    cursor: pointer;
}
.main-dashboard .white-container{
background: #fff;
height: 66px;
border: 1px solid #000;
border-radius: 6px;
/* height: calc(100vh - 453px); */
min-height: 382px;
overflow-y: auto;
overflow-x: hidden;
}
.main-dashboard .cross-section{
color: #bb3ca9;
font-size: 21px;
position: relative;
top: -5px;
left: -4px;
}
.chart-section{
height: auto;
}
.highcharts-container {
height: 340px !important;
}
.container-fluid.web-calc{
  height: 100%;
  width: 100%;
}
.main-dashboard .padding-right-0{
  padding-right: 15px;
}
.left-carousel-button{
  height:50px;
  width:21px;
  color:white;
  font-size: 20px;
  padding-top:7px;
  cursor: pointer;
  margin-left: -15px;
  border-radius:0px 5px 5px 0px;
   box-shadow: 1px 1px 1px 1px #3f3f41;
  background-color: #3f3f41;
  padding-left: 3px;
  }

@media (min-width: 992px){
  .container-fluid.web-calc{
    height: 450px;
  }
  .main-dashboard .padding-right-0{
    padding-right: 0;
  }
  .left-carousel-button{
    margin-left: -18px;
  }
}

@media (min-width: 768px) {
.widget_One, .widget_Two{
  height: 380px;
}
.widget_One .slider-list{
  overflow: hidden;
}
}

@media (max-height: 882px){
  .share-list-item-value{
    line-height: 20px;
  }
  .share-input-field{
    font-size: 13px;
    line-height: 16px;
  }
  .email-area button{
    font-size: 13px;
    line-height: 15px;
    font-family: Arial;
  }
.inline-class .second-section, .inline-class .first-section{
  margin: auto;
  position: relative;
  top: 10px;
}
}
@media (max-height: 823px){
  .answer_value {
    font-size: 1.5em;
    font-weight: bold;
  }
  .answer_title {
    font-size: 14px;
  }
  .answer_title1 {
    font-size: 17px;
  }
}

@media (max-height: 667px){
  .answer_value {
    font-size: 1.5em;
    font-weight: bold;
    position: relative;
    top: -9px;
  }
  
  .answer_title {
    font-size: 12px;
  }
  .answer_title1 {
    font-size: 14px;
  }
  hr.box-shadow {
    width: 150px;
    position: relative;
    top: -13px;
  }
  .first-section.margin_bottom_top_1 .back-gradient,
  .second-section .margin_bottom_top_1 .back-gradient
  {
    position: relative;
    top: -13px;
  }
  .error-message .error-details {
    font-size: 1em;
  }
  .error-message .error-title {
    font-size: 12px;
  }

.ci-m-auto{
line-height: 1;
}
.inline-class .second-section, .inline-class .first-section {
margin: auto;
position: relative;
top: 18px;
}
}
@media (max-height: 600px) {
  .answer_value {
    font-size: 1.5em;
    font-weight: bold;
  }
  
  .answer_title {
    font-size: 16px;
  }
  .answer_title1 {
    font-size: 12px;
  }
  
  hr.box-shadow {
    width: 150px;
  }
  /* error title */
  .error-message .error-title {
    font-size: 10px;
  }
  /* error detail */
  .error-message .error-details {
    font-size: 1em;
  }
}

@media (max-height: 568px){
.ci-m-auto {
  line-height: 0.7;
}
}


.react-orientation {
  display: none;
}

@media all and (orientation: landscape) {
  .react-orientation--landscape {
    display: inherit;
  }
}

@media all and (orientation: portrait) {
  .react-orientation--portrait {
    display: inherit;
  }
}
