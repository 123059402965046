/* left nav ul  */

ul.result-list {
    padding: 0;
    width: 100%;
    color: black;
    font-style: normal;
    font-size: 15px;
    list-style-type: none;
}

ul.result-list li {
    border-radius: 15px;
}


ul.result-list li:last-child {
    border-bottom: none
}

ul.result-list.child .inline {
    padding-left: 30px;
}

.result-list-item-emboss {
    background-image: linear-gradient(#ffffff, #cfcfcf);
    border-radius: 12px;
    padding: 3px;
    border: 2px solid #9DAAB3;
}

.result-list-item {
    border-radius: 10px;
    background-image: linear-gradient(#e4e4e4, #e9e9e9);
    display: inline-flex;
    width: 100%;
    padding-top: 2px;
    padding-bottom: 2px;
}

.result_content {
    width: 100%;
    padding-left: 10px;
    padding-top: 4px;
    padding-bottom: 4px;
}

.image_section {
    padding: 10px;
}

.list_item_title {   
    font-weight: 600;
    color:#717175;
    font-size: 16px;
    padding-top: 0;
}

.result_list_item_value {
    font-weight: 600;
    font-size: 16px;
    color: #434650;
    padding-top: 3px;
}

.expandable img {
    width: 15px !important;
    opacity: 0.3;
}

/* Screen size till max width ==400px */
@media (min-width:767px) {
    .list_item_title {   
        font-size: 18px;
        padding-top: 7px;
    }
    .result_list_item_value {
        font-size: 18px;
    }
}

