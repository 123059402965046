/*-------------------------- Inner contant fo the modal-------------------------*/

.share-area {
    margin: 5% 5%;
    height: auto;
    border-radius: 10px;
    text-align: left;
    border: 3px solid #2D8A5D;
    font-Size: 16px;
    overflow: auto;
    background: url('../../Images/main-bg-opt.png');
    background-size: 471px 521px;
}

.share-area .heading-title {
    margin-top: 25px;
    color: black;
    font-weight: bold;
    text-align: center;
    font-size: 20px;
    line-height: 20px;
}

.sendEmailArea {
    padding: 12px;
    border-radius: 10px;
    text-align: center;
}

.share-area ul {
    padding: 0;
    padding: 0;
    width: 100%;
    color: black;
    font-style: normal;
    font-size: 8px;
    list-style-type: none;
}

.share-area li {
    border-radius: 4px;
    border: 2px solid rgba(0, 0, 0, 0);
    color: black;
    margin: 10px;
    list-style: none;
}

.share-area li img {
    margin: 15px 15px 0px 15px;
}

.share-area li :disabled {
    background-color: #b6bec6;
    border: 1px solid #949799;
}

.share-area ul li:last-child {
    border-bottom: none
}

.share-area .item-outer-Wraper {
    background-image: linear-gradient(#ffffff, #cfcfcf);
    border-radius: 12px;
    padding: 3px;
    border: 2px solid #9DAAB3;
    cursor: pointer;
}

.share-area .item-inner-Wraper {
    border-radius: 10px;
    background-image: linear-gradient(#e4e4e4, #e9e9e9);
    display: inline-flex;
    width: 100%;
    padding-top: 2px;
    padding-bottom: 2px;
}

item-outer-Wraper .email-area {
    margin: 0px 10px;
    background: #e9e9e9
}

/* simple line */

.item-inner-Wraper .share-simple-line {
    width: 1px;
    background: lightgray;
}

.item-inner-Wraper span:first-child {
    background: url('../../Images/sprite-min.png');
    background-repeat: no-repeat;
    height: 30px;
    width: 37px;
    margin: auto 5px;
}

/* email area for adding email id */

.item-outer-Wraper .email-area {
    margin: 0px 15px;
    text-align: center;
}

.email-area button {
    background-image: linear-gradient(#41de91 10%, #2f8757);
    color: white;
    border-radius: 10px;
    padding: 10px 20px;
}

.share-input-field {
    background-position: center center;
    margin: 20px auto;
    padding: 10px;
    border: 2px solid #9daab3;
    border-radius: 10px;
    width: 100%;
    font-family: 'SF-Pro-Display-Bold';
    font-weight: 900;
}

.share-input-field::placeholder {
    color: lightgray;
    opacity: 1;
}

/* .share-input-field {
    color: lightgray;
} */

/* ----------------UL csss--------------- */

.share-list-item-emboss.disabled {
    pointer-events: none;
    opacity: 0.7;
}

.share-list-item-emboss.disabled .fa-lock {
    font-size: 20px;
    padding: 10px;
    color: dimgray;
}

.share-content {
    width: 100%;
    padding-left: 10px;
    padding-top: 0px;
    padding-bottom: 4px;
}

.share-LeftArrow{
    margin: auto;
}

.share-list-item-title {
    font-size: 12px;
    color: gray;
}

.share-list-item-value {
    font-weight: 600;
    font-size: 18px;
    color: #434650;
    padding-top: 3px;
}

.item-inner-Wraper span:first-child.fb {
    background-position: -2px -99px;
}
.item-inner-Wraper span:first-child.twitter {
    background-position: -33px -99px;
}
.item-inner-Wraper span:first-child.google {
    background-position: -94px -99px;
}
.item-inner-Wraper span:first-child.email {
    background-position: -64px -99px;
}
.item-inner-Wraper span:first-child.copy {
    background-position: -5px -45px;
}

@media (min-width:767px) {
    .item-inner-Wraper span:first-child.fb {
        background-position: 1px -99px;
    }
    
    .item-inner-Wraper span:first-child.twitter {
        background-position: -28px -99px;
    }
    
    .item-inner-Wraper span:first-child.google {
        background-position: -90px -99px;
    }
    
    .item-inner-Wraper span:first-child.email {
        background-position: -60px -99px;
    }

    .item-inner-Wraper span:first-child.copy {
        background-position: -5px -45px;
    }
    .share-list-item-value {
        font-size: 23px;
    }
}

@media (max-height:568px) {
    .share-list-item-title {
        font-size: 10px;
    }
    .share-list-item-value {
        font-size: 12px;
    }
}



