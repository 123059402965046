.model-container .modal-content {
    overflow: auto;
    text-align: inherit;
    height: 100%;
    line-height: 1.3em;
    padding-bottom: 100px;
}

.modal-content .calculator-header {
    display: inline-flex;
}

.modal-content .calculator-title {
    color: #2f8757;
    font-size: 22px;
    font-weight: 1000;
    padding-top: 10px;
    padding-left:10px;
    cursor: pointer;
}

/*New css style for detail list */

.scroll_area details>div {
    color: white;
    padding: 8px;
    text-align: justify;
}

.scroll_area details>p {
    color: white;
    padding: 8px;
    text-align: justify;
}

.scroll_area details>p>a>strong {

    color: #94c1e8;

}

.scroll_area  details a {
    color: white;
    font-size: 14px;
    cursor: pointer;
}

details img, iframe {
    width: 100%;
    max-width: 400px;
    height: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.scroll_area summary {
    color: white;
    padding: 8px;
    font-size: 16px;
    cursor: pointer;
    outline: none;
    text-align: justify;
}


.scroll_area {
    overflow: auto;
    /* -webkit-overflow-scrolling: initial; */
}


.web-calculator-detail details>div {
    color: #3f3f41;
    padding: 8px;
    text-align: justify;
}

.web-calculator-detail details a {
    color: white;
    font-size: 14px;
    cursor: pointer;
}

.web-calculator-detail summary {
    color: #259984;
    padding: 0;
    cursor: pointer;
    outline: none;
    text-align: justify;
    color: #259984;
    font-family: "Open Sans", 'Open Sans', sans-serif;
    font-weight: 700;
    line-height: 1.875em;
    text-decoration: underline;
    font-size: 19px;
}

.web-calculator-detail details{
    border-top: 1px solid gray;
}

.web-calculator-detail details:first-child{
    border-top: none;
}

.web-calculator-detail details:last-child {
    border-bottom: 1px solid gray;
}

/* Bottom border */
.modalbody-section details{
    border-top: 1px solid white;
}

.modalbody-section details:first-child{
    border-top: none;
}

.modalbody-section details:last-child {
    border-bottom: 1px solid white;
}

summary::-webkit-details-marker {
    display: none
  }

summary:before {
    float: left;
    height: 20px;
    width: 20px;
    content: '+';
}

details[open] summary:before {
    content: '-';
}

tbody.border-table>tr>td{
    border: 1px solid;
    padding: 10px;
}