/*-----------------Common toolbar with application title ----------------*/

.toolbar-main {
  background: transparent;
  box-shadow: none;
  height: 50px;
}

.toolbar-main {
  display: inline;
}

.toolbar-main .wrapper {
  display: inline-flex;
  width: 100%;
}

.menu-icons {
  width: 40px;
  height: 40px;
  background: url("../../Images/strip-dashboard.png");
  margin: auto;
  display: block;
  background-size: 60px;
  cursor: pointer;
}

.menu-icons.menu-icon {
  background-position: -13px -10px;
  background-size: 239px;
}

.menu-icons.share-icon {
  background-position: -17px -53px;
  background-size: 250px;
}

.cNavTitle {
  color: white;
  display: block;
  height: 35px;
  line-height: 30px;
  font-size: 18px;
  text-shadow: 0px 5px 8px rgba(0, 0, 0, 0.3);
  font-family: "SF-Pro-Display-Bold";
  font-weight: 600;
  width: 100%;
  text-align: center
}

/* Screen size till max width ==400px */

@media (min-width: 321px) and (max-width: 450px) {
  .cNavTitle {
    font-size: 12px;
    position: relative;
    top: 6px;
  }
}

@media (min-width: 451px) and (max-height: 570px) {
  .cNavTitle {
    font-size: 18px;
  }
}

@media (max-height: 568px) {
  .cNavTitle {
    font-size: 12px;
    position: relative;
    top: 3px;
  }
}

.home-logo {
  width: 100%;
  padding: 9px 0px 15px 0px;
  height: 130px;
  position: absolute;
  display: inline-block;
}

.home-logo .logo {
  width: 50px;
  height: 65px;
  background: url('../../small-logo-symbol.png');
  background-repeat: no-repeat;
  margin: auto;
  display: block;
}

.home-logo-title {
  text-align: center;
  padding: 8px;
  color: white;
  font-weight: 600;
  font-size: 16px;
  text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
  line-height: 1.3;
}

.d-block {
  display: block;
}

.calc-image {
  background: white;
  border: 3px solid #3f3f41;
  padding: 18px;
  border-radius: 15px;
  height: 70px;
  width: 70px;
}

.wrapper-container-home {
  padding-top: 154px;
  overflow-y: auto;
  overflow-x: hidden;
}

.wrapper-container-r {
  height: 500px;
  height: calc(100vh - 235px);
  overflow-y: auto;
  padding-bottom: 150px;
}

div.button-container {
  bottom: 0px;
  padding: 2%;
  position: fixed;
  width: 100%;
  height: 58px;
  background: #fff;
}

.wrapper-row {
  width: 100%;
  height: calc(100vh - 216px);
  overflow: auto;
  padding-bottom: 0;
}

@media (max-height: 667px) {
  .wrapper-row {
    padding-bottom: 70px;
  }
}

/* row item  */

.row-item img {
  border: 4px solid #3f3f41;
  padding: 12px;
  border-radius: 15px;
  height: 70px;
  width: 70px;
  box-shadow: 0px 10px 28px 1px rgba(0, 0, 0, 0.2);
  background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(246, 246, 246, 1) 47%, rgba(237, 237, 237, 1) 100%);
}

.row-item p {
  color: #3f3f41;
  margin: 4px;
  font: 400 14px/1.25 Roboto, sans-serif;
  font-family: 'Roboto-Regular';
}

/* route link */

.row-item a {
  text-decoration: none;
  display: block;
  color: black;
  font: 600;
}

.c-home-container {
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}

.home-container:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 500px;
  top: 0px;
  background: rgba(255, 255, 255, 1);
  background: radial-gradient(ellipse at center, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.57) 29%, rgba(255, 255, 255, 0.3) 47%, rgba(255, 255, 255, 0) 67%);
  background-repeat: no-repeat;
  background-position: center -250px;
  background-size: 500px 500px;
  opacity: 1;
}

.home-background {
  position: absolute;
  width: 100%;
  height: 220px;
  top: 0px;
  background: #4bb77d;
  /* Old browsers */
  background: linear-gradient(135deg, #4bb77d 0%, #259984 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

.home-background-calc {
  position: absolute;
  width: 100%;
  height: 200px;
  width: 132px;
  top: 60px;
  left: 55%;
  background: url('../../Images/bg-Calc.png')0px 0px no-repeat;
  background-size: 140px 140px;
}

.home-background-slant-cropper {
  position: absolute;
  width: 100%;
  height: 50px;
  top: 190px;
  background-color: #fff;
  overflow: hidden;
  transform: skewY(-5deg);
}

.bottomShade {
  position: absolute;
  width: 100%;
  height: 500px;
  top: 180px;
  background: rgba(0, 0, 0, 0.41);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.41) 0%, rgba(0, 0, 0, 0.29) 14%, rgba(0, 0, 0, 0.1) 36%, rgba(0, 0, 0, 0) 48%);
  background-position: center 0px;
  background-size: 100% 300px;
  background-repeat: no-repeat;
  opacity: 1;
}

/* change icons for the home page  */

.icon-container {
  height: 60px;
  width: 60px;
  display: inline-block;
  border: 1px solid #cfcfcf;
  box-shadow: 0px 0px 2px #cfcfcf;
  border-radius: 15px;
  background: rgb(254, 254, 254);
  /* Old browsers */
  background: linear-gradient(to bottom, rgba(254, 254, 254, 1) 0%, rgba(226, 226, 226, 1) 100%);
}

.calculator-icon {
  margin: 0px 0px 0px 0px;
  height: 60px;
  width: 65px;
  transform: scale(.4, .4);
  display: inline-block;
  /*background: url('../../Images/sprite-mini.png')0px 0px no-repeat;*/
  background-size: 403px 127px;
}

.calculator-icon.Auto {
  background: url('../../Images/sprite-mini1.png');
  background-position: -10px -54px;
}

.calculator-icon.auto-loan {
  background: url('../../Images/sprite-mini1.png');
  background-position: -10px -54px;
}

/* make classes same as  */

.calculator-icon.Business {
  margin: -1px 0px 0px 0px;
  background: url('../../Images/sprite-mini1.png');
  background-position: -10px -267px;
}

.calculator-icon.Credit {
  margin: -1px 0px 0px -1px;
  background: url('../../Images/sprite-mini1.png');
  background-position: -10px -417px;
}

.calculator-icon.Insurance {
  margin: -1px 0px 0px -1px;
  background: url('../../Images/sprite-mini1.png');
  background-position: -10px -647px;
}

.calculator-icon.Investment {
  margin: -2px 0px 0px -3px;
  background: url('../../Images/sprite-mini1.png');
  background-position: -10px -805px;
}

.calculator-icon.Loans {
  margin: -1px 0px 0px 3px;
  background: url('../../Images/sprite-mini1.png');
  background-position: -10px -885px;
}

.calculator-icon.Mortgage {
  margin: -1px 0px 0px -1px;
  background: url('../../Images/sprite-mini1.png');
  background-position: -10px -1045px;
}

.calculator-icon.Personal {
  background: url('../../Images/sprite-mini1.png');
  background-position: -10px -1045px;
}

.calculator-icon.Retirement {
  margin: 1px 0px 0px -1px;
  background: url('../../Images/sprite-mini4.png') no-repeat;
  background-position: -10px -10px;
}

.calculator-icon.Savings {
  background: url('../../Images/sprite-mini1.png');
  background-position: -10px -194px;
}

.calculator-icon.Tax {
  margin: -1px 0px 0px -1px;
  background: url('../../Images/sprite-mini1.png');
  background-position: -10px -726px;
}

.calculator-icon.Physics {
  background: url('../../Images/sprite-mini1.png');
  background-position: -10px -493px;
}

.calculator-icon.Thermodynamics {
  margin: -1px -14px 0px -1px;
  background: url('../../Images/sprite-mini1.png');
  background-position: -10px -1126px;
}

.calculator-icon.Math {
  margin: 0px 0px 0px -1px;
  background: url('../../Images/sprite-mini1.png');
  background-position: -10px -965px;
}

.calculator-icon.Conversion {
  margin: 0px 0px 0px -1px;
  background: url('../../Images/sprite-mini1.png');
  background-position: -10px -569px;
}

.calculator-icon.Health {
  margin: 0px 0px 0px -1px;
  background: url('../../Images/sprite-mini1.png');
  background-position: -10px -342px;
}

.calculator-icon.Lifestyle {
  background: url('../../Images/sprite-mini1.png');
  background-position: -10px -121px;
}

.picker-container .picker-column .picker-item.picker-item-selected {
  color: #2d8351;
  font-weight: bold;
  font-size: 20px;
}

.picker-item {
  color: #2d8351;
  font-weight: bold;
  font-size: 15px;
}

.wrapper-row .row-item {
  width: 31%;
  border-radius: 5px;
  text-align: center;
  vertical-align: top;
  display: inline-block;
  word-wrap: break-word;
  margin: 10px 3px;
}

.web-header {
  min-height: 370px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
  margin: 0px;
}

.widgetStyle {
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  z-index: 2 !important;
  background-size: cover;
  user-select: none;
  touch-action: manipulation;
}

.widgetStyle .slider-list {
  height: 225px !important;
}

.inline {
  display: inline-flex;
  width: 100%;
  padding: 5px;
}

.inline p {
  padding: 0;
  margin: 0;
  padding-left: 10px;
  font-weight: 400;
  width: 100%;
}

.top-menubar {
  position: fixed;
  z-index: 9;
  left: 0;
}

@media (min-width:768px) {
  .wrapper-row .row-item {
    width: 15%;
  }
  .widgetStyle .slider-list {
    height: 370px !important;
  }
}

@media (min-width:701px) {
  .home-background-slant-cropper {
    position: absolute;
    width: 100%;
    height: 50px;
    top: 200px;
    background-color: #fff;
    overflow: hidden;
    transform: skewY(0deg);
  }
  .home-background-calc {
    position: absolute;
    width: 100%;
    height: 200px;
    top: 75px;
    left: 55%;
    background: url('../../Images/bg-Calc.png')0px 0px no-repeat;
    background-size: 140px 140px;
  }
}

@media (min-width:375px) {
  .wrapper-row .row-item {
    width: 23%;
  }
}

@media (min-width:450px) and (max-width:700px) {
  .home-background-slant-cropper {
    position: absolute;
    width: 100%;
    height: 50px;
    top: 195px;
    background-color: #fff;
    overflow: hidden;
    transform: skewY(-4deg);
  }
}

@media (max-height: 667px) {
  .wrapper-container-r {
    height: calc(100vh - 225px);
  }
  div.button-container {
    bottom: 0px;
  }
}

@media (max-height: 568px) {
  .wrapper-container-r {
    height: calc(100vh - 220px);
  }
  div.button-container {
    bottom: 0px;
  }
}

/* Related calculators Css */

.header-calc-name {
  margin-top: 0;
  color: #fff;
  font-weight: 600;
  text-align: center;
  font-size: 17px;
}

.home-background.related-calc {
  height: 270px;
}

.home-background-slant-cropper.related-calc {
  height: 50px;
  top: 250px;
}

.home-title {
  height: 30px;
  /* margin-bottom: 78px; */
  color: #fff;
  font-weight: 600;
  text-align: center;
  font-size: 17px;
}

/*----------------Styles for listview in category page----------------------------*/

.related-calculator-List {
  padding: 0px;
  height: auto;
  overflow-y: auto;
  margin-top: 4px;
  font-size: 13px;
  /* opacity: 0.75; */
  padding: 0 5px;
  padding-bottom:12px;
}

.related-calculator-List li {
  margin-bottom: 10px;
}

.list-item-emboss {
  background-image: linear-gradient(#ffffff, #cfcfcf);
  border-radius: 12px;
  padding: 3px;
  border: 2px solid #9daab3;
}

.li-list-item {
  border-radius: 10px;
  background-color: White;
  display: inline-flex;
  width: 100%;
  padding-top: 2px;
  padding-bottom: 2px;
}

.list_content {
  width: 100%;
  padding: 10px 10px;
  font: 400 14px/1.25 Roboto, sans-serif;
  font-family: 'Roboto-Regular';
}

.list_content a, .related-calculator-List a {
  text-decoration: none;
  color: black !important;
}

.list_content li {
  padding: 0px;
}

/******scrollbar css****/

::-webkit-scrollbar {
  width: 0px;
  height: 12px;
}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}

/**********/

div.main-container {
  color: white;
  height: 100%;
  background-size: cover;
}

.bgGradient {
  padding-top: 56px;
  position: absolute;
  width: 100%;
  overflow-y: hidden;
  height: 100%;
  background: #4bb77d;
  background: linear-gradient(135deg, #4bb77d 0%, #259984 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4bb77d', endColorstr='#259984', GradientType=1);
  /* IE6-9 fallback on horizontal gradient */
}

/**----------------------------Extra css category-web--------------------------**/

.height-100per {
  height: 100%;
}

/*---------------------------------------- Calculate loans csss------------------------------ */

.calculate-container {
  border: 4px solid #4bb77d;
  border-radius: 15px;
  overflow: hidden;
  background: url('../../Images/main-bg-opt.png');
  background-size: 471px 521px;
  box-shadow: 2px 2px 11px 2px rgba(78, 181, 125, 0.52);
}

.calculate-container.p-t {
  padding-top: 65px;
}

.calculate-container.p-b {
  padding-bottom: 70px;
  touch-action: manipulation;
}

.calculate-container.conversion-c {
  padding-bottom: 10px;
  padding-top: 10px;
}

.calculate-container.conversion-c .question_content {
  overflow: hidden;
  display: block;
}

.h100-per {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.top-nav-2 {
  margin-top: -65px;
}

.bottom-nav-2 {
  margin-bottom: -70px;
  border-radius: 0% 0% 15px 15px;
  touch-action: manipulation;
}

.panel-background {
  background: url('../../Images/main-bg-opt.png');
  background-size: 471px;
  display: inline-flex;
}

.answer-detail {
  color: white;
  font-size: 15px;
}

.margin_bottom_top_1>div {
  color: white;
  text-align: center;
}

.widget_One, .widget_Two {
  font-size: 16px;
  overflow: auto;
  height: 100%;
  background: url("../../Images/main-bg-opt.png");
  background-size: 471px 521px;
}

.widget_One .slider-list {
  height: 380px !important;
  overflow-y: auto;
  overflow-x: hidden;
}

.widget_One {
  overflow: visible;
}

.widget_One .slider {
  overflow: visible;
}

.widget_One .slider-frame {
  overflow: visible !important;
}

.previous-img {
  position: inherit !important;
}

.widget_One .slider ul {
  overflow: visible;
}

.height-70per .widget_One .slider-list {
  height: 366px !important;
}

.widget_Two {
  padding-left: 10px;
  padding-right: 10px;
}

.widget_One ul, .widget_Two ul {
  padding: 0;
}

.widget_One li, .widget_Two li {
  border-radius: 4px;
  border: 2px solid rgba(0, 0, 0, 0);
  color: #396571;
  list-style: none;
  touch-action: manipulation;
}

.widget_One li .tooltip-icon {
  padding: 2px;
  cursor: pointer;
  width: 40px;
  height: 40px;
  background: url("../../Images/strip-dashboard.png");
  margin: auto;
  display: block;
  background-size: 236px;
  background-position: -128px -79px;
}

.widget_One li .tooltip-icon-conv {
  padding: 2px;
  cursor: pointer;
  width: 40px;
  height: 40px;
  background: url("../../Images/convicon.png");
  margin: auto;
  display: block;
  background-size: 40px 40px;
  background-repeat: no-repeat;
}

.widget_One li.disabled, .widget_Two li :disabled {
  background-color: #b6bec6;
  border: 1px solid #949799;
}

.back-gradient {
  padding-top: 6px;
  background-image: radial-gradient( at top, rgba(0, 0, 0, 0.2) 40%, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
}

.answer_value {
  font-size: 1.8em;
  font-weight: bold;
  text-shadow: 0px 5px 6px rgba(0, 0, 0, 0.3);
}

.answer_value_conversion {
  font-size: 1.3em;
  font-weight: bold;
  text-shadow: 0px 5px 6px rgba(0, 0, 0, 0.3);
}

.answer_title {
  text-shadow: 0px 5px 6px rgba(0, 0, 0, 0.3);
  font-size: 16px;
  font-family: "SF-Pro-Display-SemiBold";
}

.answer_title1 {
  text-shadow: 0px 5px 6px rgba(0, 0, 0, 0.3);
  font-size: 20px;
  font-family: "SF-Pro-Display-SemiBold";
}

.carousel_widget {
  font-size: 16px;
  overflow: auto;
  height: 100%;
  background: url("../../Images/main-bg-opt.png");
  background-size: 471px 521px;
}

hr.box-shadow {
  width: 140px;
  background-color: #2f8757;
  padding: 0px;
  margin-bottom: 0px;
  height: 2px;
  border: none;
  margin-top: 4px;
  box-shadow: 0px 6px 42px -1px rgba(0, 0, 0, 1);
}

.carousel_widget ul {
  padding: 0;
}

.carousel_widget li {
  border-radius: 4px;
  border: 1px solid lightgray;
  background-color: #fff;
  color: black;
  margin: 10px;
  list-style: none;
  box-shadow: 1px 1px 1px 1px #b7b2b2;
}

.carousel_widget li img {
  margin: 20px;
}

.carousel_widget li.disabled {
  background-color: #b6bec6;
  border: 1px solid #949799;
}

.float-left {
  float: left;
  width: 100%;
  display: inline-flex;
}

.width-40p {
  width: 40%
}

.width-60p {
  width: 60%
}

.widget-30-per {
  padding-top: 156px;
}

.widget-10-per {
  padding-top: 52px;
}

.widget-header-30per {
  height: 156px;
  margin-top: -156px;
  line-height: 1.7em;
}

.widget-header-10per {
  height: 52px;
  margin-top: -52px;
}

.fa-chevron-right, .fa-chevron-left {
  color: #7c8e95 !important;
  width: 43px !important;
  height: 45px !important;
  position: relative;
  top: -12px;
}

.fa-chevron-right:before, .fa-chevron-left:before {
  font-size: 25px !important;
}

/* Header container */

.info-icon {
  position: absolute;
  right: 15px;
  bottom: 0;
  width: 30px;
  height: 30px;
  background: url("../../Images/strip-dashboard.png");
  margin: auto;
  display: block;
  background-size: 116px;
  cursor: pointer;
  background-position: -59px 1px;
}

.relative {
  position: relative;
}

/* header information for error  */

.error-message {
  color: white;
  text-align: center;
  padding: 10px;
  margin-top: 5px;
  height: 90%;
}

.error-message .error-title, .error-details {
  color: white;
}

/* error title */

.error-message .error-title {
  font-size: 16px;
}

/* error detail */

.error-message .error-details {
  font-size: 1.2em;
}

.error-message .error-img {
  background: white;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  text-align: center;
  display: inline-flex;
  margin: 10px;
}

.error-img img {
  margin: auto;
}

.carousel-wraper {
  height: 100%;
  background: url("../../Images/gridDark.png") center 111px no-repeat;
  background-size: "auto 70px";
}

.header-carousel-wraper {
  height: 100%;
  border: '2px 2px 3px 3px'
}

.right-carousel-button {
  height: 50px;
  width: 21px;
  cursor: pointer;
  color: white;
  font-size: 20px;
  margin-right: -14px;
  padding-top: 6px;
  padding-left: 2px;
  border-radius: 5px 0px 0px 5px;
  box-shadow: 0px 0px 1px 1px #3f3f41;
  background-color: #3f3f41;
  padding-left: 5px;
}

.carousel-wraper .carousel-indicators {
  width: 100%;
}

.carousel-indicators .ci-m-auto {
  margin: auto;
  z-index: 999999999;
}

.group-title {
  padding: 1px 10px;
  font-weight: 600;
  font: 400 13px/1.25 Roboto, sans-serif;
  font-size: 2em;
  pointer-events: none;
}

.inline-class {
  display: flex !important;
}

.inline-contact {
  display: contents !important;
  width: 100%;
}

.header-data {
  color: white;
}

.answer_title_web {
  text-shadow: 0px 5px 6px rgba(0, 0, 0, 0.3);
  font-size: 22px;
  font-family: "SF-Pro-Display-SemiBold";
  margin-bottom: -2px;
}

.answer_value_web {
  font-size: 2em;
  font-weight: bold;
  text-shadow: 0px 5px 6px rgba(0, 0, 0, 0.3);
  margin-top: -4px;
}

.hr-line {
  width: 240px;
  background-color: #2f8757;
  padding: 0px;
  margin-bottom: 5px;
  height: 2px;
  border: none;
  margin-top: -3px;
}

.inline-class .second-section, .inline-class .first-section {
  margin: auto;
}

.previous-img {
  background: url('../../Images/button.png') no-repeat;
  padding: 17px 40px;
  background-size: 80px 49px;
  min-width: 80px !important;
}

.arrow-left-icon {
  background: url('../../Images/leftArrow.png') no-repeat;
  padding: 17px 9px;
  background-size: 13px 21px;
  position: relative;
  top: 7px;
  left: 1px;
}

.arrow-right-icon {
  transform: rotate(180deg);
  top: -6px;
}

.arrow-footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 71px;
  border-top: 1px solid lightgray;
}

.arrow-footer .section-one {
  width: 32%;
  margin-right: 0;
  padding-left: 16px;
  position: relative;
  touch-action: manipulation;
}

.arrow-footer .section-second {
  width: 38%;
  text-align: center;
  touch-action: manipulation;
}

.arrow-footer .section-third {
  width: 32%;
  text-align: right;
  padding-right: 16px;
  position: relative;
  touch-action: manipulation;
}

.arrow-footer .pages-details {
  font-size: 20px;
  color: #000;
  font-weight: bold;
  user-select: none;
}

/*-------------dashboard- right- container-------------*/

.main-dashboard .right-container {
  height: 100%;
  background: #4bb77d;
  /* Old browsers */
  background: linear-gradient(135deg, #4bb77d 0%, #259984 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  min-height: 412px;
  margin-top: 45px;
  border-radius: 0px 10px 10px 0px;
  box-shadow: 2px 2px 11px 2px rgba(78, 181, 125, 0.52);
  padding: 25px;
}

.main-dashboard .no-padding {
  padding: 0;
}

.main-dashboard .white-cross-container {
  width: 45px;
  background: #fff;
  border: 1px solid #000;
  border-radius: 6px 6px 0px 0px;
  border-bottom: 0;
  padding-left: 19px;
  position: absolute;
  right: 31px;
  margin-top: -8px;
  margin-top: -20px;
  height: 21px;
  cursor: pointer;
}

.main-dashboard .white-container {
  background: #fff;
  height: 66px;
  border: 1px solid #000;
  border-radius: 6px;
  /* height: calc(100vh - 453px); */
  min-height: 382px;
  overflow-y: auto;
  overflow-x: hidden;
}

.main-dashboard .cross-section {
  color: #bb3ca9;
  font-size: 21px;
  position: relative;
  top: -5px;
  left: -4px;
}

.chart-section {
  height: auto;
}

.highcharts-container {
  height: 340px !important;
}

.widget_One.conversion-inputs, .widget_One.conversion-inputs .slider-list {
  min-height: 488px;
}

.conversion-inputs .questions-list li:first-child .question-list-item .input-control-converion {
  border-radius: 12px;
  height: 70px;
  font-size: 40px;
  font-weight: bold;
}

.conversion-inputs .questions-list li:first-child .question_content {
  padding: 0;
}

.conversion-inputs .questions-list li:first-child .question-list-item {
  padding: 0;
  background: #fff;
}

.conversion-inputs .questions-list li:first-child .list_item_value {
  border-radius: 12px;
  height: 60px;
  font-size: 40px;
  font-weight: bold;
  position: relative;
  top: -3px;
  left: 5px;
}

/* .questions-list li:last-child .question_content .dropdown-content{
    margin-top: -165px;
  } */

.container-fluid.web-calc {
  height: 100%;
  width: 100%;
}

.main-dashboard .padding-right-0 {
  padding-right: 15px;
}

.left-carousel-button {
  height: 50px;
  width: 21px;
  color: white;
  font-size: 20px;
  padding-top: 7px;
  cursor: pointer;
  margin-left: -15px;
  border-radius: 0px 5px 5px 0px;
  box-shadow: 1px 1px 1px 1px #3f3f41;
  background-color: #3f3f41;
  padding-left: 3px;
}

.right-header-container {
  height: 100%;
  background: #4bb77d;
  background: linear-gradient(135deg, #4bb77d 0%, #259984 100%);
  height: auto;
  min-height: 412px;
  margin-top: 45px;
  border-radius: 0px 10px 10px 0px;
  box-shadow: 2px 2px 11px 2px rgba(78, 181, 125, 0.52);
  padding-top: 20px;
  padding-left: 15px;
  padding-bottom: 20px;
  padding-right: 15px;
  margin-top: 0;
  border-radius: 0px 0 10px 10px;
  margin-left: 60px;
  margin-right: 60px;
}

@media (min-width: 992px) {
  .container-fluid.web-calc {
    height: 450px;
  }
  .main-dashboard .padding-right-0 {
    padding-right: 0;
  }
  .left-carousel-button {
    margin-left: -18px;
  }
  .right-header-container {
    margin-top: 45px;
    border-radius: 0px 10px 10px 0px;
    margin-left: 0;
    margin-right: 0;
  }
}

@media (min-width: 768px) {
  .calculate-container {
    min-height: 496px;
  }
  .group-title {
    margin: 0;
    padding-left: 0;
  }
}

@media (max-height: 882px) {
  .widget_One {
    overflow: hidden;
  }
  .group-title {
    margin: 0;
    padding-left: 0;
  }
  .modalbody-section .panel-heading a {
    color: white;
    padding: 8px;
    font-size: 14px;
    cursor: pointer;
    outline: none;
    text-align: justify;
    font-weight: 100;
    font-style: normal;
    text-decoration: none;
    position: inherit;
    display: inherit;
  }

  .modalbody-section .panel {
    margin-top: 5px;
    margin-bottom: 5px;
    margin-right: 5px;
    margin-left: 5px;
    border: 0;
    border-top: 1px solid #eee;
  }
  .modalbody-section .panel-body {
    color: white;
    padding: 5px;
    text-align: justify;
  }
  .modalbody-section .panel-heading a.collapsed .icon-arrow {
    background: url('../../Images/arrow-right-green.png') no-repeat;
    padding: 15px;
    float: right;
    position: relative;
    top: 3px;
  }
  .modalbody-section .panel-heading a .icon-arrow {
    background: url('../../Images/arrow-bottom-green.png') no-repeat;
    padding: 15px;
    float: right;
    position: relative;
    top: 3px;
  }
  .share-list-item-value {
    line-height: 20px;
  }
  .share-input-field {
    font-size: 13px;
    line-height: 16px;
  }
  .email-area button {
    font-size: 13px;
    line-height: 15px;
    font-family: Arial;
  }
  .inline-class .second-section, .inline-class .first-section {
    margin: auto;
    position: relative;
    top: 10px;
  }
}

@media (max-height: 823px) {
  .answer_value {
    font-size: 1.5em;
    font-weight: bold;
  }
  .answer_title {
    font-size: 14px;
  }
  .answer_title1 {
    font-size: 17px;
  }
  .widget_One.conversion-inputs, .widget_One.conversion-inputs .slider-list {
    min-height: 446px;
  }
}

@media (max-height: 667px) {
  .answer_value {
    font-size: 1.5em;
    font-weight: bold;
    position: relative;
    top: -9px;
  }
  .answer_title {
    font-size: 12px;
  }
  .answer_title1 {
    font-size: 14px;
  }
  hr.box-shadow {
    width: 150px;
    position: relative;
    top: -13px;
  }
  .first-section.margin_bottom_top_1 .back-gradient, .second-section .margin_bottom_top_1 .back-gradient {
    position: relative;
    top: -13px;
  }
  .error-message .error-details {
    font-size: 1em;
  }
  .error-message .error-title {
    font-size: 12px;
  }
  .widget_One.conversion-inputs, .widget_One.conversion-inputs .slider-list {
    min-height: 488px;
  }
  .height-70per .widget_One.conversion-inputs, .height-70per .widget_One.conversion-inputs .slider-list {
    min-height: 400px;
  }
  .ci-m-auto {
    line-height: 1;
  }
  .inline-class .second-section, .inline-class .first-section {
    margin: auto;
    position: relative;
    top: 18px;
  }
}

@media (max-height: 600px) {
  .answer_value {
    font-size: 1.5em;
    font-weight: bold;
  }
  .answer_title {
    font-size: 16px;
  }
  .answer_title1 {
    font-size: 12px;
  }
  hr.box-shadow {
    width: 150px;
  }
  /* error title */
  .error-message .error-title {
    font-size: 10px;
  }
  /* error detail */
  .error-message .error-details {
    font-size: 1em;
  }
}

@media (max-height: 812px) {
  .list_item_value {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .widget_One li .tooltip-icon-conv {
    width: 30px;
    height: 30px;
    background-size: 30px 30px;
  }
}

@media (max-height: 568px) {
  .height-70per .widget_One.conversion-inputs, .height-70per .widget_One.conversion-inputs .slider-list {
    min-height: 330px;
    max-height: 330px;
  }
  .ci-m-auto {
    line-height: 0.7;
  }
  .height-100per .question_content {
    overflow: hidden;
    display: block !important;
    height: auto !important;
    padding-top: 10px !important;
  }
}

/* Questions list */

ul.questions-list {
  padding: 0;
  width: 100%;
  color: black;
  font-style: normal;
  font-size: 12px;
  list-style-type: none;
  text-align: left;
}

ul.questions-list li {
  margin: 10px 8px;
}

.simple-line {
  width: 1px;
  background: lightgray;
}

ul.child li:first-child {
  border-top: 1px solid #3f3f41;
}

ul.questions-list li:last-child {
  border-bottom: none;
}

ul.questions-list.child .inline {
  padding-left: 30px;
}

.question-list-item-emboss {
  background-image: linear-gradient(#ffffff, #cfcfcf);
  border-radius: 12px;
  padding: 6px;
  border: 2px solid #9daab3;
}

.question-list-item.np .white-bg {
  background-color: white;
}

.list-item-con {
  background-color: white;
  border-radius: 10px;
}

.question-list-item-emboss.disabled {
  pointer-events: none;
  opacity: 0.7;
}

.question-list-item-emboss.disabled .fa-lock {
  font-size: 20px;
  padding: 10px;
  color: dimgray;
}

.question-list-item {
  border-radius: 10px;
  background-image: linear-gradient(#e4e4e4, #e9e9e9);
  display: inline-flex;
  width: 100%;
  padding-top: 2px;
  padding-bottom: 2px;
  user-select: none;
}

.question_content {
  width: 100%;
  height: 80px;
  display: grid;
  align-items: center;
  align-content: center;
  /* padding-left: 25px; */
  /* overflow: hidden; */
  padding-top: 0px;
  padding-bottom: 4px;
}

/* this Css is used in conversation page  */

.select_input_text {
  font-size: 25px;
  font-weight: 800;
  padding: 5px;
}

.conversion_text {
  font-size: 18px;
  font-weight: 600;
  width: 100%;
}

div.right-arrow {
  margin: auto;
  margin-right: 5px;
}

img.right-arrow {
  width: 15px;
  opacity: 0.2;
}

.list_item_title {
  font-size: 16px;
  color: #717175;
}

.list_item_value {
  font-weight: 600;
  font-size: 20px;
  color: #434650;
  padding-top: 0;
  padding-left: 10px;
}

.list_item_value_conv {
  border-radius: 12px;
  height: 60px;
  width: 240px;
  font-size: 40px;
  font-weight: 700;
  position: relative;
  top: -3px;
  left: 5px;
}

@media (min-width: 767px) {
  .list_item_title {
    font-size: 18px;
  }
  .list_item_value {
    font-size: 23px;
    padding-top: 4px;
  }
  .bgGradient {
    position: relative;
    background: #fff;
  }
  .bgGradient .pad-right-header-container {
    height: 100%;
    background: #4bb77d;
    background: -webkit-linear-gradient(315deg, #4bb77d 0%, #259984 100%);
    background: -o-linear-gradient(315deg, #4bb77d 0%, #259984 100%);
    background: linear-gradient(135deg, #4bb77d 0%, #259984 100%);
    height: auto;
    min-height: 412px;
    margin-top: 45px;
    border-radius: 0px 10px 10px 0px;
    -webkit-box-shadow: 2px 2px 11px 2px rgba(78, 181, 125, 0.52);
    box-shadow: 2px 2px 11px 2px rgba(78, 181, 125, 0.52);
    padding-top: 20px;
    padding-left: 15px;
    padding-bottom: 20px;
    padding-right: 15px;
    margin-top: 0;
    border-radius: 0px 0 10px 10px;
    margin-left: 60px;
    margin-right: 60px;
    margin-top: 60px;
    border-radius: 0px 10px 10px 0px;
    margin-left: 0;
    margin-right: 0;
  }
  .bgGradient .toolbar-main {
    background: linear-gradient(135deg, #4bb77d 0%, #259984 100%);
  }
  .bgGradient .wrapper {
    background: linear-gradient(135deg, #4bb77d 0%, #259984 100%);
  }
  .bgGradient .i-pad-gray-panel {
    position: relative;
    /* background-image: url(/static/media/bg-inner-page.png); */
    padding-top: 50px;
    display: flex;
  }
  .i-pad-gray-panel .section-left {
    width: 50%;
    float: left;
  }
  .i-pad-gray-panel .section-right {
    width: 50%;
    padding-right: 15px;
  }
  .bgGradient .widget-header-30per {
    margin-top: 0;
  }
  .bgGradient .error-message {
    min-height: 370px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 0px;
    margin: 0px;
  }
  .bgGradient .pad-right-header-container .carousel-wraper {
    background: none;
  }
}

#tags:hover {
  font-weight: bolder;
}

.expand-view {
  font-size: 1em;
  color: #444;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  text-align: left;
  border: none;
  outline: none;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.expand-view .title {
  max-width: 50px;
}

.expand-view .accordion-title-text {
  width: 100%;
}

@media screen and (orientation: landscape) {
  .portrait-mode {
    /* Rotate the content container */
    transform: rotate(-90deg);
    transform-origin: left top;
    /* Set content width to viewport height */
    width: 100vh;
    /* Set content height to viewport width */
    height: 100vw;
    overflow-x: hidden;
    position: absolute;
    top: 100%;
    left: 0;
  }
}