/* Modal Content */

.i-pad-gray-panel .keyboard-section {
    position: absolute;
    width: 50%;
    top: 58px;
    height: 518px;
    border-radius: 10px;
    padding: 0 21px 0 0;
}

.i-pad-gray-panel.conversion-section .keyboard-section {
    padding: 0 4px 0 18px;
}

.i-pad-gray-panel .model-container {
    position: relative;
    border-radius: 10px;
}

.i-pad-gray-panel .model-container .close-btn-div {
    border-radius: 10px 10px 0 0;
}


.model-container .key-content {
    width: 100%;
    height: auto;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
    overflow: hidden;
    touch-action: manipulation;
}

/* Title for the keyboard */
.key-content .input-title {
    color: #444444;
    text-align: center;
    font: bold;
    font-size: 20px;
    font-weight: 1000;
    margin-top: 70px;
}

/* Key input field */
.key-content .input-field {
    background-color: #ffffff;
    background-image: url('../../Images/griddouble.png');
    background-size: 100vw;
    background-position: center center;
    margin: 20px auto;
    padding: 30px;
    border: 3px solid #2D8A5D;
    width: 100%;
    border-radius: 10px;
    font-size: 28px;
    text-align: center;
    font-family: 'SF-Pro-Display-Bold';
    font-weight: 900;
}

/* input span icon*/

.input-field span.icon {
    font-size: 20px;
    padding: 5px;
}

/* input span value*/

.input-field span.input-value {
    color: black;
}

.isError-maxValue{
     color: red;
    font-size: 1.4em;
    height: 30px;
}

.isError-maxValue1{
  display:none;
  height: 30px;
}

/* input keys wraper */

.key-content .input-keys-wrapper-postion {
    display: block;
    position: absolute;
    bottom: 0px;
    width: 100%;
    left: 0px;
    padding: 15px;
}

.key-content .input-keys-wrapper {
    background-image: url('../../Images/main-bg-opt.png');
    background-size: 471px 521px;
    background-color: #ffffff;
    margin: auto;
    padding: 10px;
    border: 3px solid #2D8A5D;
    width: 98%;
    border-radius: 10px;
    font-size: 28px;
    margin-top: 30px;
    text-align: center;
    touch-action: manipulation;
}

.key-content .input-keys-table {
    width: 100%;
}

.key-content .input-keys-table td {
    width: 30%;
    background-image: linear-gradient(#ffffff 10%, #cfcfcf);
    border-radius: 10px;
    margin: 2px;
    padding: 4px;
    border: 1px solid #7f9199;
}


/* The Close Button */

.close-icon {
    color: white;
    float: right;
    font-size: 30px;
    font-weight: bold;
    background-repeat: no-repeat;
    position: absolute;
    right: 3px;
    top: 3px;
    border: 1px solid white;
    padding: 3px;
    height: 30px;
    width: 30px;
    border-radius: 30px;
}


/* Button animation */
.input-keys-wrapper .delbutton button {
    background: #e9e9e9 url('../../Images/delete-icon.png') center center no-repeat;
    height: 44px;
}

.input-keys-wrapper .green {
    background-image: linear-gradient(#7ff0b9 10%, #145f36) !important;
}

.input-keys-wrapper .green button {
    background-image: linear-gradient(#41de91 10%, #2f8757);
    color: white;
}

.input-keys-wrapper button {
    position: relative;
    display: block;
    background-color: #e9e9e9;
    border: none;
    color: #000000;
    font-size: 28px;
    padding: 0px;
    width: 100%;
    text-align: center;
    line-height: 32px;
    /* Safari */
    transition-duration: 0.4s;
    -webkit-transition-duration: 0.4s;
    text-decoration: none;
    overflow: hidden;
    cursor: pointer;
    border-radius: 5px;
    font-family: 'SF-Pro-Display-Bold';
    font-weight: 900;
}

.input-keys-wrapper button1:after {
    content: "";
    background: #90EE90;
    display: block;
    position: absolute;
    padding-top: 300%;
    padding-left: 350%;
    margin-left: -20px!important;
    margin-top: -120%;
    opacity: 0;
    transition: all 0.8s
}

.input-keys-wrapper button:active:after {
    padding: 0;
    margin: 0;
    opacity: 1;
    transition: 0s;
}

.input-keys-wrapper table {
    margin: 0 auto;
    border-spacing: 6px;
    border-collapse: inherit;
    touch-action: manipulation;
}

.input-keys-wrapper button.width_100per {
    width: 100%;
}

@media (max-height:812px) {
    .isError-maxValue{
       font-size: 1.2em;
    }
    .input-keys-wrapper button {
        font-size: 20px;
        padding: 6px;
        width: 100%;
    }
}

@media (max-height:512px) {
    .input-keys-wrapper button {
        font-size: 18px;
        padding: 3px;
        width: 100%;
    }
    .key-content .input-keys-wrapper{
        margin-top: 0px;
    }
    .key-content .input-title {
        margin-top: 0px;
        font-size: 17px;
    }
}

@media ( max-height: 600px) {
    .input-keys-wrapper button {
        line-height: 20px;
    }
    .input-keys-wrapper .delbutton button {
        height: 32px;
    }
    .model-container .input-field {
        padding: 20px;
        margin-top: 0px;
        margin-bottom: 9px;
        font-size: 23px;
    }
    .key-content .input-title {
        margin-top: 27px;
        font-size: 17px;
    }
}


@media
only screen and (-webkit-min-device-pixel-ratio: 2),
only screen and (   min--moz-device-pixel-ratio: 2),
only screen and (     -o-min-device-pixel-ratio: 2/1),
only screen and (        min-device-pixel-ratio: 2),
only screen and (                min-resolution: 192dpi),
only screen and (                min-resolution: 2dppx) { 
    .input-keys-wrapper button {
        line-height: 20px;
    }
    .input-keys-wrapper .delbutton button {
        height: 32px;
    }
    .model-container .input-field {
        padding: 11px 20px;
        margin-top: 0px;
        margin-bottom: 9px;
        font-size: 23px;
    }
    .key-content .input-title {
        margin-top: 27px;
        font-size: 17px;
        margin-bottom: 12px;
    }

}