.inline {
    display: inline-flex;
    width: 100%;
    padding: 5px;
}

.inline p {
    padding: 0;
    margin: 0;
    padding-left: 10px;
    font-weight: 400;
    width: 100%;
}

.inline-15per{
    display: inline-flex;
    width: 100%;
    padding: 5px;
    height:15%;
}
.related-calc-scroll{
overflow: auto;
height: 72%;
}

/* left nav ul  */

ul.nav-menu {
    padding: 0;
    width: 100%;
    color: white;
    font-style: normal;
    font-size: 15px;
    text-shadow: 0px 5px 6px rgba(0, 0, 0, .3);
}

ul.nav-menu li {
    padding-top: 8px;
    padding-bottom: 8px;
    border-bottom: 1px solid #3F424B;
    user-select: none;
}

ul.child li {
    padding-left: 20px;
    display: inline-flex;
    width: 100%;
}

ul.child li a {
    padding-left: 0px;
}

ul.child li a:hover, a:focus {
    color: #1cec86;
    text-decoration: none;
}

ul.child li .dot {
    height: 5px;
    width: 5px;
    background-color: #3f3f41;
    border-radius: 50%;
    display: inline-block;
    margin: auto 10px;
    border-bottom: 1px solid;
}

ul.nav-menu li:last-child {
    border-bottom: none
}

ul.nav-menu.child .inline {
    padding-left: 30px;
}

/* Hide list items  */

ul.hide {
    display: none;
}

ul.view {
    display: block;
    transition: width 600ms ease-out, height 600ms ease-out;
}
 
/*List menu for navigation*/

.list-icon {
    width: 20px;
    height: 20px;
    background: url('../../Images/strip-dashboard.png');
}
.plus-icon{
    width: 23px;
    height: 23px;
    background: url("../../Images/strip-dashboard.png");
    margin: auto;
    display: block;
    background-size: 140px;
    cursor: pointer;
    background-position: 171px 241px;
}
.minus-icon{
    width: 23px;
    height: 23px;
    background: url("../../Images/strip-dashboard.png");
    margin: auto;
    display: block;
    background-size: 140px;
    cursor: pointer;
    background-position: 171px 217px;
}

.list-icon.home {
    background: url("../../Images/strip-dash2.png");
    background-position: -10px -164px;
}

.list-icon.related_calculators {
    background: url("../../Images/strip-dash2.png");
    background-position: -10px -47px;
}

.list-icon.calendar {
    background-position: -10px -10px;
}

.list-icon.help_icon {
    background: url("../../Images/strip-dash2.png");
    background-position: -10px -10px;
}

.list-icon.info {
    background-position: -150px -33px;
}

.list-icon.aboutUs {
    background: url("../../Images/strip-dash2.png");
    background-position: -10px -84px;
}

.list-icon.privacy {
    background: url("../../Images/strip-dash2.png");
    background-position: -10px -124px;
}