@font-face {
    font-display: optional;
    font-family: MuseoSans300;
    font-weight: 400;
    font-style: normal;
    src: url(../../fonts/MuseoSans300/MuseoSans300.eot);
    src: url(../../fonts/MuseoSans300/MuseoSans300.eot?#iefix) format("embedded-opentype"), url(../../fonts/MuseoSans300/MuseoSans300.woff) format("woff"), url(../../fonts/MuseoSans300/MuseoSans300.ttf) format("truetype");
    unicode-range: U+0000-00FF
}

@font-face {
    font-display: optional;
    font-family: MuseoSans500;
    font-weight: 400;
    font-style: normal;
    src: url(../../fonts/MuseoSans500/MuseoSans500.eot);
    src: url(../../fonts/MuseoSans500/MuseoSans500.eot?#iefix) format("embedded-opentype"), url(../../fonts/MuseoSans500/MuseoSans500.woff) format("woff"), url(../../fonts/MuseoSans500/MuseoSans500.ttf) format("truetype");
    unicode-range: U+0000-00FF
}

@font-face {
    font-display: optional;
    font-family: MuseoSans700;
    font-weight: 400;
    font-style: normal;
    src: url(../../fonts/MuseoSans700/MuseoSans700.eot);
    src: url(../../fonts/MuseoSans700/MuseoSans700.eot?#iefix) format("embedded-opentype"), url(../../fonts/MuseoSans700/MuseoSans700.woff) format("woff"), url(../../fonts/MuseoSans700/MuseoSans700.ttf) format("truetype");
    unicode-range: U+0000-00FF
}

@font-face {
    font-display: optional;
    font-family: MuseoSans900;
    font-weight: 400;
    font-style: normal;
    src: url(../../fonts/MuseoSans900/MuseoSans900.eot);
    src: url(../../fonts/MuseoSans900/MuseoSans900.eot?#iefix) format("embedded-opentype"), url(../../fonts/MuseoSans900/MuseoSans900.woff) format("woff"), url(../../fonts/MuseoSans900/MuseoSans900.ttf) format("truetype");
    unicode-range: U+0000-00FF
}

@font-face {
    font-display: optional;
    font-family: MuseoSansRegular;
    font-weight: 400;
    font-style: normal;
    src: url(../../fonts/MuseoSansRegular/MuseoSansRegular.eot);
    src: url(../../fonts/MuseoSansRegular/MuseoSansRegular.eot?#iefix) format("embedded-opentype"), url(../../fonts/MuseoSansRegular/MuseoSansRegular.woff) format("woff"), url(../../fonts/MuseoSansRegular/MuseoSansRegular.ttf) format("truetype");
    unicode-range: U+0000-00FF
}

::-webkit-input-placeholder {
    color: rgba(145, 145, 145, .7)
}

:-ms-input-placeholder {
    color: rgba(145, 145, 145, .7)
}

::-ms-input-placeholder {
    color: rgba(145, 145, 145, .7)
}

::placeholder {
    color: rgba(145, 145, 145, .7)
}

::-moz-selection {
    /* background-color: #e46931; */
    background-color: #259984;
    color: #fff;
}

::selection {
    background-color: #259984;
    color: #fff;
}

body {
    font-size: 16px;
    min-width: 320px;
    position: relative;
    box-sizing: border-box;
    line-height: 1.7;
    font-family: "Open Sans", sans-serif;
    overflow-x: hidden;
    color: #3f3f41;
    margin: 0;
    padding: 0;
}

.boxwrapper {
    box-shadow: 3px 3px 3px
}

/* ----------------------------- */

div.content {
    font-size: 18px;
    box-sizing: border-box
}

div.content img {
    display: block;
    margin: 0px auto;
    max-width: 100%;
    height: auto
}

div.content h3 {
    font-size: 18px;
    font-weight: 700;
    color: #80a74e;
    text-align: center
}

div.content dl {
    text-align: justify;
    margin: 0 0 20px 0
}

div.content dl dt {
    font-weight: 700;
    color: #80a74e;
    cursor: pointer;
    text-align: left !important;
    font-size: 28px;
    line-height: 1.5;
    text-shadow: 0.5px 0.5px 0 darkslategrey
}

div.content dl dt:before {
    content: "";
    background: url('../../ImagesWeb/bullet.png') 0 9px no-repeat;
    padding-left: 20px
}

div.content dl dt a {
    font-size: 28px;
    text-decoration: underline
}

div.content dl dd {
    font-size: 18px;
    line-height: 30px;
    font-weight: 400;
    color: #31353d;
    padding: 5px 0 10px 20px;
    font-family: Abel, sans-serif
}

div.content h2 {
    font-size: 30px;
    font-weight: 700;
    color: #80a74e;
    text-align: inherit
}

div.content p {
    padding: 10px 0
}

div.content p {
    margin-bottom: 0;
    color: #4b4846;
    font-size: 16px;
    line-height: 30px;
    font-weight: 300;
    text-align: center;
}

div.content a {
    font-size: 16px
}

div.content strong {
    font-weight: bold
}

div.content em {
    font-style: italic
}

div.content table {
    width: 100%;
    padding: 0;
    border: 1px solid #31353d
}

div.content table td, div.content table th {
    padding: 0 5px 5px;
    border: 1px solid #31353d;
    text-align: center;
    vertical-align: middle
}

div.content table tr:first-child {
    font-weight: bold
}

div.content table th {
    border-bottom-width: 3px
}

div.content table tr:nth-child(2n - 1) {
    background: #eee
}

div.content .align-cell-content-top {
    vertical-align: top
}

/* _____ */

body input, body textarea {
    border: #666 1px solid;
    outline: 0
}

body input:focus:required:invalid, body textarea:focus:required:invalid {
    border-color: red
}

body input:required:valid, body textarea:required:valid {
    border-color: green
}

.img-responsive {
    display: block;
    max-width: 100%;
    height: auto
}

.img-responsive.img-width {
    width: 100%
}

.text-center {
    text-align: center
}

.text-right-footer {
    text-align: right;
    padding-bottom: 50px;
}

.logo-bottom {
    background-image: url('../../medium-logo.png');
    background-size: 196px;
    background-repeat: no-repeat;
    width: 200px;
    height: 58px;
    float: right;
}

b, strong {
    font-weight: 700
}

.accented-btn {
    display: inline-block;
    padding: 9px 20px 12px;
    background-color: #e46931;
    border-radius: 22px;
    box-shadow: 0 8px 16px rgba(228, 105, 49, .25);
    color: #fff;
    text-align: center;
    transition: all .4s
}

.accented-btn.big-btn {
    padding: 10px 24px 12px;
    font-family: MuseoSansRegular, 'Open Sans', sans-serif;
    text-transform: uppercase
}

.accented-btn:focus, .accented-btn:hover {
    background-color: orange;
    text-decoration: none;
    color: #fff
}

.white-btn {
    padding: 10px 21px 12px;
    background-color: #fff;
    color: #e46931;
    font-family: MuseoSans500, 'Open Sans', sans-serif;
    vertical-align: middle;
    box-shadow: 0 8px 16px rgba(247, 163, 65, .32)
}

.white-btn:focus, .white-btn:hover {
    background-color: #fff;
    color: #e46931;
    cursor: pointer;
    box-shadow: 0 8px 16px rgba(255, 255, 255, .32)
}

.text-center {
    text-align: center
}

h1, h2 {
    font-family: MuseoSans900, 'Open Sans', sans-serif;
    text-transform: none;
    text-align: center
}

h1 {
    font-size: 4em;
    color: #fff
}

h2 {
    font-family: MuseoSans900, 'Open Sans', sans-serif;
    font-size: 1.71429em
}

.container-fluid {
    max-width: 1290px
}

.list-inline {
    padding: 0;
    margin: 0;
    list-style: none
}

.list-inline li {
    display: inline-block;
    vertical-align: middle
}

.breadcrumbs-wrapper li {
    position: relative;
    font-family: 'Open Sans', 'Open Sans', sans-serif
}

.breadcrumbs-wrapper li:not(:last-child) {
    padding-right: 25px
}

.breadcrumbs-wrapper li:not(:last-child):before {
    content: '';
    position: absolute;
    top: 9px;
    right: 8px;
    width: 7px;
    height: 7px;
    border-right: 1px solid #949799;
    border-bottom: 1px solid #949799;
    font-family: 'Open Sans', 'Open Sans', sans-serif;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    color: #949799
}

.breadcrumbs-wrapper li a {
    color: #949799;
    font-size: .92857em
}

.breadcrumbs-wrapper li a.breadcrumbs-active {
    color: #464748;
    text-decoration: underline
}

.home-page {
    position: relative;
    background-image: url('../../ImagesWeb/bg-home.png');
}

.layout-page {
    position: relative;
    background-image: url('../../ImagesWeb/bg-inner-page.png');
}

.bg-main {
    background-repeat: no-repeat;
    background-size: 100% 720px;
    background-position: center 0
}

.top-panel {
    padding: 10px 0 50px;
    padding-bottom: 20px;
    margin-bottom: 0
}

.top-panel .logo-wrapper {
    position: relative;
    padding-right: 30px;
    margin-left: 20px;
}

.top-panel .logo-wrapper:before {
    content: '';
    z-index: 0;
    position: absolute;
    top: -10px;
    left: -42px;
    width: 290px;
    height: 165px;
    /*background-size: 100% 100px;
     background-image: url('../../ImagesWeb/cloud.png');
    background-repeat: no-repeat */
}

.top-panel .logo-wrapper * {
    z-index: 2;
    position: relative;
}

.top-panel .logo-wrapper-black * {
    z-index: 2;
    position: relative;
    opacity: 0.7;
}

.top-panel .logo-wrapper-black img {
    left: 15px;
}

.top-panel-menu {
    padding-top: 8px;
    position: relative;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    max-width: 400px;
    text-align: center;
    margin: auto;
}

.footer-menu {
    text-align: center;
    margin-bottom: 20px
}

.footer-menu li {
    display: block;
    padding: 0 0 10px
}

.top-panel-menu li {
    padding: 4px 10px;
    display: block
}

.footer-menu li:last-child, .top-panel-menu li:last-child {
    padding-right: 0
}

.footer-menu li:first-child, .top-panel-menu li:first-child {
    padding-left: 0
}

.footer-menu li a, .top-panel-menu li a {
    position: relative;
    color: #fff;
    font-family: MuseoSansRegular, 'Open Sans', sans-serif;
    transition: all .4s
}

.footer-menu li a:not(.accented-btn):after, .top-panel-menu li a:not(.accented-btn):after {
    content: '';
    position: absolute;
    bottom: -3px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    height: 2px;
    width: 0;
    background-color: #e46931;
    transition: all .4s
}

.footer-fontsize {
    font-size: 14px
}

.footer-menu li a:focus, .footer-menu li a:hover, .top-panel-menu li a:focus, .top-panel-menu li a:hover {
    text-decoration: none
}

.footer-menu li a:focus:after, .footer-menu li a:hover:after, .top-panel-menu li a:focus:after, .top-panel-menu li a:hover:after {
    width: 100%
}

.footer-menu li a.active:after, .top-panel-menu li a.active:after {
    width: 100%
}

.margin2 {
    margin: 5px 12.5% 0 12.5%;
    padding: 20px 10px 7px 10px;
/*     background: linear-gradient(120deg, rgba(71,179,126,0.7) 0%,rgba(38,153,131,0.7) 98%);
    border: 2px solid linear-gradient(120deg, rgba(71,179,126,0.5) 0%,rgba(38,153,131,0.5) 98%);
    border-radius: 15px;
    box-shadow: 2px 2px 20px rgba(24, 101, 87, 0.15); */
}

/* width */
::-webkit-scrollbar {
    width: 8px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: rgba(255,255,255,0);

  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgba(255,255,255,0.35); 
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(102,102,102,0.5); 
  }

.heading-section {
    overflow: hidden;
    position: relative;
    padding-bottom: 45px
}

.heading-section h1 {
    /* margin: 32px 0 -2px; */
    line-height: 1.2;
}

.heading-section h1+p {
    margin-top: 0;
    color: #fff;
    font-family: MuseoSans300, 'Open Sans', sans-serif;
    font-size: 1.57143em;
    text-align: center;
}

.form-search-calculator {
    max-width: 640px;
    margin: 70px auto 0
}

.form-search-calculator .form-group {
    position: relative
}

.form-search-calculator .form-group input {
    width: 100%;
    border: none;
    border-radius: 32px;
    background-color: #ffffff;
    font-family: HelveticaNeueMedium, 'Open Sans', sans-serif;
    font-size: 1.14286em;
    color: rgba(145, 145, 145, .7);
    line-height: 1;
    padding: 13px 55px 15px 20px;
}

.form-search-calculator .form-group button {
    position: absolute;
    z-index: 3;
    top: 0;
    right: 0;
    width: 75px;
    height: 70px;
    padding: 0;
    border: none;
    outline: 0;
    border-radius: 0 32px 32px 0;
    background-image: url(../../ImagesWeb/icon-search.png);
    background-repeat: no-repeat;
    background-position: 23px 19px;
    box-shadow: none;
    width: 50px;
    height: 50px;
    background-position: 11px 10px
}

.h2style {
    color: #3f3f41;
}

.bg-math {
    position: fixed;
    left: 50%;
    top: 90px;
    width: 290px;
    height: 336px;
    margin-left: 360px;
    background-image: url(../../ImagesWeb/math-operators.png);
    background-repeat: no-repeat;
    background-size: cover
}

.section-calculator-list {
    padding: 0 15px 100px
}

.section-calculator-list .card-item {
    min-height: 0;
}

.section-calculator-list.section-cal .card-item {
    min-height: auto;
}

.section-calculator-list .all-links-wrapper {
    z-index: 2;
    position: relative;
    padding-top: 10px;
    margin-bottom: -70px
}

.section-calculator-list-wrapper {
    position: relative;
    /* padding: 70px 50px 2648px; */
    padding: 70px 20px 0;
    border-radius: 16px;
    background-color: #fff;
    box-shadow: 0 16px 32px rgba(69, 69, 69, .16)
}

.section-calculator-list-wrapper:after {
    content: '';
    z-index: 0;
    position: absolute;
    left: 0;
    right: 0;
    height: 65px;
    bottom: -50px;
    background-color: #fff
}

.card-item {
    position: relative;
    max-width: 33F6px;
    margin: 0 auto 40px;
    border: 2px solid transparent;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 6px 18px rgba(69, 69, 69, .1);
    transition: all .5s;
    padding: 28px 20px 52px
}

.card-item header {
    padding-bottom: 50px
}

.card-item h2 {
    margin: 6px 0 2px;
    color: #3f3f41
}

.card-item h2+p {
    margin: 0;
    color: rgba(86, 86, 86, .4);
    font-family: MuseoSans500, 'Open Sans', sans-serif;
    font-size: 1.07143em;
    line-height: .5;
    text-align: center
}

.card-item:hover {
    background-color: #fff;
    border: 2px solid #00ce7d;
    border-radius: 8px;
    box-shadow: 0 32px 32px rgba(75, 183, 125, .32)
}

/**
.card-item:hover span {
}
**/

.card-icon {
    min-height: 36px;
    text-align: center;
    vertical-align: middle
}

.card-icon img {
    vertical-align: middle
}

.card-body p {
    margin: 0 0 21px;
    color: #526662;
    font-family: MuseoSansRegular, 'Open Sans', sans-serif;
    font-size: 1em;
    line-height: 1.42857em
}

.card-link-wrapper {
    position: absolute;
    bottom: 26px;
    left: 0;
    right: 0;
    text-align: center
}

.card-link-wrapper a, .card-link-wrapper span {
    display: inline-block;
    margin-right: 10px;
    font-family: MuseoSansRegular, 'Open Sans', sans-serif;
    font-size: 1em;
    color: #259984;
    font-weight: bold
}

.card-link-wrapper img {
    margin-top: -1px
}

.main-footer {
    position: relative;
    padding: 80px 0 30px;
    background-image: url(../../ImagesWeb/top-triangle.png);
    background-repeat: no-repeat;
    background-size: 100% 100%
}

.main-footer .divider {
    margin: 20px 0
}

.divider {
    width: 100%;
    height: 2px;
    background-color: rgba(255, 255, 255, .1)
}

.footer-menu li {
    padding: 0 22px
}

.footer-menu li a {
    font-family: "Open Sans", sans-serif
}

.rights {
    text-align: center;
    padding-top: 10px
}

.rights li {
    color: rgba(255, 255, 255, .5)
}

.rights li:last-child {
    padding-left: 0 20px;
    text-transform: uppercase
}

.sprite {
    background-image: url(../../ImagesWeb/spritesheet.png);
    background-repeat: no-repeat;
    display: block
}

.sprite-icon-1 {
    width: 33px;
    height: 31px;
    background-position: 0 0
}

.sprite-icon-2 {
    width: 23px;
    height: 43px;
    background-position: -33px 0
}

.sprite-icon-3 {
    width: 29px;
    height: 37px;
    background-position: -56px 0
}

.sprite-icon-4 {
    width: 41px;
    height: 37px;
    background-position: -85px 0
}

.sprite-icon-5 {
    width: 40px;
    height: 28px;
    background-position: -126px 0
}

.sprite-icon-6 {
    width: 50px;
    height: 35px;
    background-position: -166px 0
}

.sprite-icon-ambrella {
    width: 36px;
    height: 35px;
    background-position: -216px 0
}

.sprite-icon-bucks {
    width: 32px;
    height: 36px;
    background-position: -252px 0
}

.sprite-icon-bucks-bar {
    width: 56px;
    height: 56px;
    background-position: -284px 0
}

.sprite-icon-bucks-house {
    width: 32px;
    height: 30px;
    background-position: -340px 0
}

.sprite-icon-calculator {
    width: 56px;
    height: 56px;
    background-position: -372px 0
}

.sprite-icon-calender {
    width: 36px;
    height: 38px;
    background-position: -428px 0
}

.sprite-icon-car {
    width: 36px;
    height: 25px;
    background-position: -464px 0
}

.sprite-icon-glassed {
    width: 46px;
    height: 20px;
    background-position: -500px 0
}

.sprite-icon-house {
    width: 36px;
    height: 34px;
    background-position: -546px 0
}

.sprite-icon-plus-minus {
    width: 56px;
    height: 56px;
    background-position: -582px 0
}

.sprite-icon-rate-house {
    width: 34px;
    height: 23px;
    background-position: -638px 0
}

.sprite-icon-suitcase {
    width: 36px;
    height: 34px;
    background-position: -672px 0
}

.quicktip {
    color: green;
}

.sticky {
    position: absolute;
    right: 0;
    z-index: 50;
    transform: rotate(5deg);
    width: 200px;
    min-height: 150px;
    margin: -10px 10px 10px;
    padding: 10px;
    font-family: "Comic Sans MS", "Comic Sans", "Chalkboard SE", "Comic Neue", cursive;
    font-size: 14px;
    color: #000;
    background: rgba(255, 255, 51, 0.8);
    box-shadow: -2px 2px 2px rgba(0, 0, 0, 0.3);
}

.sticky:before, .sticky:after {
    content: "";
    display: block;
    position: absolute;
    width: 16px;
    height: 16px;
    top: 0;
    right: 0;
}

.sticky:before {
    border-top: solid 8px #fff;
    border-right: solid 8px #fff;
    border-left: solid 8px transparent;
    border-bottom: solid 8px transparent;
}

.sticky:after {
    border-bottom: solid 8px #dddd33;
    border-left: solid 8px #dddd33;
    border-right: solid 8px transparent;
    border-top: solid 8px transparent;
}

.common-page .section-calculator-list-wrapper {
    padding: 40px 20px;
}

.category-page.bg-main {
    background-size: 100% 502px;
    background-color: #f5faf9
}

.common-page.bg-main {
    background-size: 100% 452px;
    background-color: #f5faf9
}

.category-page h1 {
    margin-top: 25px;
    font-size: 1.78571em;
    line-height: 1
}

.common-page h1 {
    margin-top: 25px;
    font-size: 1.78571em;
    line-height: 1
}

.category-page .section-calculator-list {
    padding-bottom: 0 0 60px
}

.common-page .section-calculator-list {
    padding-bottom: 110px
}

.category-page .section-calculator-list-wrapper:after, .common-page .section-calculator-list-wrapper:after {
    background-color: transparent
}

div.content a {
    color: #80a74e;
    transition: all .5s
}

div.content table {
    width: 100%;
    padding: 0;
    border: 1px solid #31353d
}

div.content table tr {
    background-color: transparent;
    transition: all .5s
}

div.content table tr:first-child {
    font-weight: 700
}

div.content table tr:nth-child(2n-1) {
    background-color: #eee
}

div.content table tr:hover {
    background-color: #b5e7a0;
    color: #3f3f41;
    font-weight: 700;
}

div.content table tr:hover a {
    color: #d4f5ba
}

div.content table th {
    border-bottom-width: 3px
}

/* -------------------------------------custom-css----------------------------------------- */

.category-page h1:before {
    content: '';
    display: inline-block;
    width: 64px;
    height: 61px;
    margin-right: 20px;
    -webkit-transform: scale(.7);
    transform: scale(.7);
    vertical-align: -15px;
    background-image: url(../../ImagesWeb/icon-house-white.png)
}

.category-page .section-calculator-list-wrapper {
    padding-top: 65px;
    padding-bottom: 30px
}

.category-page .section-calculator-list-wrapper h2 {
    position: relative;
    max-width: 1130px;
    margin: 70px auto 50px;
    color: #3d4d49;
    font-family: MuseoSansRegular, 'Open Sans', sans-serif;
    font-size: 1.71429em;
    line-height: 1.1;
    text-transform: none;
    text-align: left;
    margin: 50px auto;
}

.category-page .section-calculator-list-wrapper h2 span {
    z-index: 2;
    position: relative;
    display: inline-block;
    padding-right: 20px;
    background-color: #fff
}

.category-page .section-calculator-list-wrapper h2:after {
    content: '';
    z-index: 0;
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    height: 2px;
    background-color: rgba(0, 0, 0, .1)
}

.category-page .form-search-calculator {
    margin: 0 auto 25px
}

.category-page .form-search-calculator .form-group input {
    background-color: #f6f6f6
}

.category-page .card-item {
    min-height: 185px;
    padding: 45px 20px 60px;
    margin: 0 auto 35px;
    box-shadow: 0 6px 18px rgba(69, 69, 69, .22)
}

.category-page .card-item:hover {
    background-color: #fff;
    border: 2px solid #00ce7d;
    border-radius: 8px;
    box-shadow: 0 32px 32px rgba(75, 183, 125, .32);
    cursor: pointer
}

/**
.category-page .card-item:hover span {
}
**/

.category-page .card-link-wrapper {
    top: 0;
    bottom: 0;
    border-radius: 8px;
    text-align: right
}

.category-page .card-link-wrapper span {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 25px;
    padding-right: 32px;
    margin-right: 0
}

.category-page .card-link-wrapper span img {
    margin-left: 10px
}

.card-icon-text {
    position: relative
}

.card-icon-text .icon-wrapper {
    display: table-cell;
    vertical-align: middle;
    width: 65px
}

.card-icon-text p {
    display: table-cell;
    vertical-align: middle;
    position: relative;
    margin-bottom: 0;
    color: #259984;
    font-family: MuseoSansRegular, 'Open Sans', sans-serif;
    font-size: 1.14286em;
    font-weight: 700;
    line-height: 1.31
}

.main-footer.white-footer {
    background-image: url(../../ImagesWeb/triangle-footer-white.png);
    padding: 40px 0 30px;
}

.main-footer.white-footer .footer-menu {
    margin-bottom: 0;
    text-align: left;
    padding-bottom: 24px;
}

.main-footer.white-footer .footer-menu li a {
    color: rgba(41, 51, 49, .6)
}

.main-footer.white-footer .rights {
    padding-bottom: 34px;
    text-align: center;
}

.main-footer.white-footer .rights li {
    color: rgba(41, 51, 49, .6)
}

.main-footer.white-footer .rights li:first-child {
    padding-right: 0px
}

.main-footer.white-footer .rights li:last-child {
    padding-left: 0
}

.soc-nets-sprite {
    display: inline-block;
    height: 34px;
    background-image: url(../../ImagesWeb/soc-nets-buttons.png);
    transition: all .4s;
    box-shadow: 0 0 4px transparent
}

.soc-nets-sprite:hover {
    box-shadow: 0 0 8px #38579a
}

.soc-nets-facebook {
    width: 181px;
    background-position: 0 0
}

.soc-nets-twitter {
    width: 161px;
    background-position: -181px 0
}

.soc-nets-twitter:hover {
    box-shadow: 0 0 8px #1da7e2
}

.soc-nets-pinterest {
    width: 176px;
    background-position: -342px 0
}

.soc-nets-pinterest:hover {
    box-shadow: 0 0 8px #ca1d21
}

.soc-nets-youtube {
    width: 170px;
    background-position: -518px 0
}

.soc-nets-youtube:hover {
    box-shadow: 0 0 8px #cf1d13
}

.soc-nets-linkedin {
    width: 173px;
    background-position: -688px 0
}

.soc-nets-linkedin:hover {
    box-shadow: 0 0 8px #01659b
}

.soc-nets-instagram {
    width: 178px;
    background-position: -861px 0
}

.soc-nets-instagram:hover {
    box-shadow: 0 0 8px #db357d
}

.footer-logo-wrapper {
    padding-top: 31px
}

.inner-page .container-fluid {
    max-width: 990px
}

.inner-page .main-footer .container-fluid {
    max-width: 1290px
}

.inner-page .bg-main {
    background-size: 100% 540px
}

.inner-page .top-panel {
    padding: 10px 0 25px
}

.inner-page .top-panel .logo-wrapper:before {
    height: 0;
    background-image: none
}

.inner-page .top-panel-menu li a {
    color: rgba(70, 71, 72, .7)
}

.inner-page .heading-section {
    padding-bottom: 0
}

.inner-page h1 {
    margin: 52px 0 48px;
    color: rgba(69, 69, 69, .9);
    font-family: MuseoSans700, 'Open Sans', sans-serif;
    font-size: 2.28571em;
    line-height: 1.15
}

.section-calculator-description {
    padding: 30px 0 50px
}

.section-calculator-description p {
    margin-bottom: 18px;
    /* text-align: justify; */
    padding-left: 10px;
}

.faq-wrapper {
    padding: 25px 0 0
}

.faq-wrapper img {
    max-width: 100%;
}

.faq-wrapper h2 {
    margin: 0 0 30px;
    margin: 0 0 5px;
    text-align: left;
    color: #424242;
    font-family: MuseoSansRegular, 'Open Sans', sans-serif;
    font-size: 2.28571em;
    font-size: 1.2em;
    line-height: 1.15625em
}

.faq-wrapper .panel {
    padding-left: 35px;
    position: relative;
}

.faq-wrapper .accordion-item {
    border-top: 1px solid #eee;
}

.collapse {
    display: none;
    height: 0;
    transition: all 1s;
    box-sizing: border-box
}

.collapse.in {
    display: block;
    height: auto;
    overflow: hidden
}

.collapsing {
    display: block
}

.panel-heading h3 {
    margin-bottom: 0;
    font-size: 1.14286em
}

.panel-heading a {
    position: relative;
    color: #259984;
    font-family: "Open Sans", 'Open Sans', sans-serif;
    font-weight: 700;
    line-height: 1.875em;
    text-decoration: underline;
    font-size: 20px
}

.panel-heading a.collapsed {
    text-decoration: none
}

.panel-heading a.collapsed:before {
    content: '+'
}

.panel-heading a:before {
    content: '-';
    position: absolute;
    left: -32px;
    top: -6px;
    text-decoration: none
}

.panel-heading a:focus {
    outline: 0
}

.get-calculator-wrapper {
    max-width: 450px;
    padding: 33px;
    margin: 60px auto;
    background-color: #e46931;
    background-image: url(../../ImagesWeb/math-operators.png), url(../../ImagesWeb/bg-gradient.png);
    background-repeat: no-repeat;
    background-size: 100px 120px, 100% 100%;
    background-position: 112% 95%;
    border-radius: 8px;
    color: #fff
}

.get-calculator-wrapper h3 {
    margin: 10px 0 18px;
    text-align: center;
    font-family: MuseoSansRegular, 'Open Sans', sans-serif;
    font-size: 1.28571em;
    line-height: 1.55556em
}

.get-calculator-wrapper p {
    color: rgba(255, 255, 255, .8)
}

.get-calculator-wrapper+.img-wrapper {
    margin-left: 0px
}

.get-calculator-wrapper+.img-wrapper img {
    margin: auto
}

.get-calculator-wrapper .buttons-wrapper {
    padding-top: 10px
}

.get-calculator-wrapper .a-view-code {
    float: right;
    margin-top: 10px;
    color: rgba(255, 255, 255, .7);
    font-family: MuseoSans500, 'Open Sans', sans-serif;
    text-decoration: underline
}

.get-calculator-wrapper .a-view-code:hover {
    cursor: pointer
}

.get-calculator-wrapper .pre {
    box-sizing: border-box;
    min-height: 250px;
    padding: 5px;
    margin-bottom: 25px;
    border: 1px solid rgba(255, 255, 255, .6);
    transition: all .6s
}

.get-calculator-wrapper textarea {
    overflow: hidden;
    width: 100%;
    min-height: 250px;
    border: none;
    background-color: transparent
}

.get-calculator-wrapper .copy-active {
    z-index: 0;
    display: block;
    position: absolute;
    opacity: 0
}

.email-pdf-buttons {
    text-align: center;
    padding: 10px;
}

.email-pdf-buttons button {
    margin: 5px;
}

.email-pdf-buttons-two {
    text-align: center;
    padding: 10px;
    border-radius: 8px;
    background: rgb(66, 125, 255);
    border: 2px solid rgb(66, 125, 255);
    color: white;
    cursor: pointer;
}

.email-pdf-buttons-two:hover {
    background-color: white;
    border: 2px solid #4bb77d;
    color: #259984;
}

.email-pdf-buttons-three {
    text-align: center;
    width: 400px;
    padding: 10px;
    border-radius: 8px;
    background: #4bb77d;
    border: 2px solid #4bb77d;
    color: white;
    cursor: pointer;
}

.email-pdf-buttons-three:hover {
    background-color: white;
    border: 2px solid #4bb77d;
    color: #259984;
}

table { 
    border-spacing: 5px; 
    border: 2px solid black;
}

td { 
    padding: 10px; 
    border: 1px solid black;
}

table {
    margin: auto;
}

.buttons-wrapper {
    position: relative
}

.buttons-wrapper .tooltip {
    z-index: 0;
    position: absolute;
    top: -20px;
    left: 40px;
    padding: 1px 10px;
    border: 1px solid #fff;
    border-radius: 20px;
    transition: all .4s;
    visibility: hidden;
    opacity: 0
}

.buttons-wrapper .tooltip.visible {
    visibility: visible;
    opacity: 1
}

.calculator-wrapper {
    padding: 35px 25px 30px;
    border-radius: 12px;
    background-color: #fff;
    box-shadow: 8px 0 16px rgba(26, 28, 42, .32)
}

.calculator-steps {
    overflow: hidden;
    height: 43px;
    background-color: #e4e4e4;
    background-image: linear-gradient(#ffffff, #e4e4e4);
    border: 1px solid #cccccc;
    border-radius: 22px
}

.calculator-steps span {
    z-index: 2;
    position: relative;
    float: left;
    display: block;
    width: 34%;
    padding: 13px 0 15px;
    text-shadow: 0 1px 0 #fff;
    color: #44484f;
    font-family: MuseoSans300, 'Open Sans', sans-serif;
    font-size: 1em;
    font-weight: 600;
    line-height: 1;
    text-align: center
}

.calculator-steps span:last-child {
    width: 32%
}

.calculator-steps span:after, .calculator-steps span:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 49%;
    left: -2px;
    right: 10px;
    z-index: -1;
    border-right: 1px solid #cccccc;
    -webkit-transform: skew(30deg);
    transform: skew(30deg);
    /* -webkit-transform-origin: 0 0; */
    transform-origin: 0 0;
    transition: all .4s
}

.calculator-steps span:after {
    top: 49%;
    left: -2px;
    bottom: 0;
    -webkit-transform: skew(-30deg);
    transform: skew(-30deg);
    -webkit-transform-origin: 0 100%;
    transform-origin: 0 100%
}

.calculator-steps span:first-child {
    padding-left: 6px
}

.calculator-steps span:first-child:after, .calculator-steps span:first-child:before {
    left: -16px;
    right: 2px
}

.calculator-steps span:last-child:after, .calculator-steps span:last-child:before {
    right: -100px;
    left: -10px
}

.calculator-steps span.active-step {
    color: #e46931
}

.calculator-steps span.active-step:before {
    background: linear-gradient(#cfcfcf, #e4e4e4);
    border-color: #c6c7c8
}

.calculator-steps span.active-step:after {
    background: linear-gradient(#e4e4e4, #e9e9e9);
    border-color: #c6c7c8
}

.blog-page h1:before {
    content: '';
    display: inline-block;
    width: 80px;
    height: 65px;
    margin-right: -5px;
    -webkit-transform: scale(.5);
    transform: scale(.5);
    vertical-align: -22px;
    background-image: url(../../ImagesWeb/blog/icon-notation.png)
}

.blog-card {
    position: relative;
    max-width: 324px;
    padding: 0 0 32px;
    margin: 0 auto 80px;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 6px 20px rgba(69, 69, 69, .2);
    font-family: MuseoSans300, "Open Sans", sans-serif;
    transition: all .5s
}

.blog-card .blog-body, .blog-card .blog-link-wrapper, .blog-card header {
    padding: 0 30px
}

.blog-card .blog-body {
    max-height: 100px;
    overflow: hidden;
    margin-bottom: 20px
}

.blog-card img {
    max-height: 180px;
    overflow: hidden
}

.blog-card h3 {
    max-height: 56px;
    overflow: hidden;
    margin: 34px 0 12px;
    color: #259984;
    font-family: MuseoSans300, "Open Sans", sans-serif;
    font-size: 1.71429em;
    line-height: 1.20833em;
    font-weight: 400
}

.blog-card time {
    color: #babcbc;
    font-size: 1.14286em
}

.blog-card p {
    color: #616d6b;
    font-size: 1.14286em
}

.blog-link-wrapper a, .blog-link-wrapper span {
    display: inline-block;
    margin-right: 16px;
    font-family: MuseoSansRegular, "Open Sans", sans-serif;
    font-size: 1em;
    color: #e46931
}

.blog-link-wrapper img {
    margin-top: -1px
}

.pagination-list {
    margin: -26px -10px 0;
    text-align: center;
    margin-top: -10px
}

.pagination-list li:not(:last-child) {
    padding-right: 5px
}

.pagination-list li a {
    display: inline-block;
    width: 44px;
    background-color: #ffffff;
    border-radius: 16px;
    font-family: MuseoSans500, "Open Sans", sans-serif;
    font-size: 1.14286em;
    line-height: 44px;
    color: #7e8b88;
    text-decoration: none;
    transition: all .4s
}

.pagination-list li a.active, .pagination-list li a:hover {
    background-color: #e46931;
    color: #fff
}

.breadcrumb-link {
    color: #fff;
    font-family: MuseoSans500, "Open Sans", sans-serif;
    font-size: 1.14286em;
    line-height: 1;
    transition: all .4s
}

.breadcrumb-link:hover {
    color: #fff
}

.post-wrapper {
    overflow: hidden;
    background-color: #fff;
    border-radius: 16px;
    box-shadow: 0 16px 32px rgba(69, 69, 69, .16)
}

.tagcloud-wrapper h3 {
    position: relative;
    height: 30px;
    padding: 2px 0 0 15px;
    border-left: 3px solid #80a74e;
    font-size: 1em
}

.tagcloud-wrapper h3:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    border-bottom: 1px solid #ffffff
}

.tagcloud a {
    display: inline-block;
    min-height: 27px;
    padding: 1px 7px;
    margin: 0 10px 10px 0;
    border-radius: 2px;
    background-color: #ffffff;
    font-size: .92857em;
    font-weight: 600;
    line-height: 25px;
    color: #80a74e;
    text-decoration: none;
    transition: all .6s;
    box-shadow: 0 1px 0 0 #eeeeee
}

.tagcloud a:hover {
    color: #fff;
    background-color: #80a74e
}

.main-footer.white-footer.footer-img {
    background-image: url('../../ImagesWeb/triangle-footer-white.png')
}

/* .section-calculator-list .card-item header{
    padding-bottom: 40px
} */

.common-page h1:before {
    -webkit-transform: scale(.7);
    transform: scale(.7)
}

.common-page .heading-section {
    padding-bottom: 70px;
}

/* .category-page .heading-section{
    padding-bottom: 70px
} */

.main-footer.white-footer .footer-soc-nets {
    text-align: center;
}

.box-tooltip .tooltiptext {
    left: 0;
    top: -155px;
    top: 60px;
}

.box-tooltip .tooltip .tooltiptext::before {
    top: 143px;
    left: 115px;
    transform: rotate(270deg);
    top: -10px;
    transform: rotate(90deg);
}

@media (min-width:1299px) {
    .top-panel-menu li {
        padding: 4px 18px;
    }
    .section-calculator-list {
        padding-bottom: 150px
    }
    .section-calculator-list-wrapper {
        padding: 70px 50px 70px;
    }
    .card-item {
        padding: 32px;
        padding-bottom: 60px;
    }
    .footer-menu li {
        padding: 0 22px
    }
}

@media (min-width:1030px) {
    .top-panel .logo-wrapper-black img {
        left: 0;
    }
}

@media (min-width:992px) {
    .divider-md {
        width: 100%;
        height: 1px
    }
    .top-panel .logo-wrapper {
        padding-right: 0;
    }
    .top-panel .logo-wrapper:before {
        left: -82px;
        width: 444px;
        height: 146px;
        background-size: auto;
    }
    /* .heading-section h1 {
        margin: 32px 0 -2px;
        font-size: 4em;
    } */
    .heading-section h1+p {
        font-size: 2.28571em;
    }
    .footer-menu {
        margin-bottom: 0;
        text-align: left;
    }
    .rights {
        text-align: right;
    }
    .category-page .section-calculator-list {
        padding-bottom: 110px;
    }
    .category-page .section-calculator-list-wrapper {
        padding-bottom: 75px
    }
    .category-page .section-calculator-list-wrapper h2 {
        margin: 70px auto 50px;
    }
    .main-footer.white-footer .footer-menu {
        padding-bottom: 28px
    }
    .get-calculator-wrapper {
        margin: 60px 0 40px -10px;
    }
    .get-calculator-wrapper+.img-wrapper {
        margin-left: -10px
    }
    .get-calculator-wrapper+.img-wrapper img {
        margin: 0;
    }
}

@media (min-width:767px) {
    .top-panel {
        padding: 10px 0 50px;
    }
    .top-panel-menu {
        padding-top: 8px;
        margin-left: auto;
        -webkit-transform: translateY(-70px);
        transform: translateY(0px);
        max-width: none;
        text-align: right;
    }
    .top-panel-menu li {
        display: inline-block;
    }
    .form-search-calculator .form-group input {
        padding: 20px 84px 22px 35px;
    }
    .form-search-calculator .form-group button {
        width: 75px;
        height: 67px;
        background-position: 23px 19px;
    }
    /* .section-calculator-list{
        padding-bottom: 120px;
    } */
    .section-calculator-list .card-item {
        min-height: 100px
    }
    /* .section-calculator-list .card-item header{
        padding-bottom: 0;
    } */
    .section-calculator-list .all-links-wrapper {
        padding-top: 60px;
    }
    .main-footer {
        padding: 70px 0 50px;
    }
    .main-footer .divider {
        margin: 40px 0
    }
    .rights {
        padding-top: 0;
    }
    .rights li:last-child {
        padding: 0;
        padding-left: 50px;
    }
    .common-page h1 {
        font-size: 2.85714em;
    }
    .common-page h1:before, .category-page h1:before {
        -webkit-transform: none;
        transform: none;
    }
    .common-page .heading-section {
        padding-bottom: 0;
    }
    /* .category-page .section-calculator-list{
        padding-bottom: 90px
    } */
    /* .category-page .heading-section{
        padding-bottom: 0px
    } */
    .category-page h1 {
        font-size: 2.85714em;
    }
    .main-footer.white-footer {
        padding: 100px 0 50px;
    }
    .main-footer.white-footer .footer-menu, .main-footer.white-footer .footer-soc-nets, .main-footer.white-footer .rights {
        text-align: left;
    }
    /* .main-footer.white-footer .footer-menu {
        padding-bottom: 0;
    } */
    .main-footer.white-footer .rights li:first-child {
        padding-right: 50px
    }
    .section-calculator-description {
        padding: 65px 0 50px
    }
    .faq-wrapper {
        padding: 60px 30px 0 0
    }
    .blog-page h1:before {
        margin-right: 35px;
        vertical-align: -15px;
    }
}

@media (min-width:480px) {
    /* .top-panel {
        padding-bottom: 0;
        margin-bottom: -70px;
    } */
    .top-panel-menu {
        /* padding-top: 40px; */
        margin-left: 0;
        -webkit-transform: none;
        transform: none;
        max-width: 100%;
        text-align: right;
        margin: 0;
    }
    /* .card-item {
        padding: 32px 15px 60px
    } */
    .card-link-wrapper {
        bottom: 28px;
    }
    .footer-menu li {
        display: inline-block;
    }
    .common-page h1 {
        margin-top: 45px;
    }
    .common-page .section-calculator-list-wrapper {
        padding-bottom: 50px;
    }
    .common-page .section-calculator-list-wrapper {
        padding: 0 0 60px;
    }
    .category-page.bg-main {
        background-size: 100% 550px;
    }
    .category-page h1 {
        margin-top: 45px;
    }
    .category-page .card-item {
        padding: 45px 40px 60px;
    }
    /* .category-page .section-calculator-list{
        padding-bottom: 60px
    } */
    .blog-page h1:before {
        margin-right: 5px;
        -webkit-transform: none;
        transform: none;
    }
    .pagination-list {
        margin: -26px -10px 0;
    }
    .pagination-list li:not(:last-child) {
        padding-right: 12px
    }
}

@media (max-height:420px) {
    .main-footer.white-footer .footer-logo-wrapper {
        padding: 0 0 30px;
        text-align: center
    }
    .main-footer.white-footer .rights {
        padding: 0 0 34px
    }
    .main-footer.white-footer .rights li {
        display: block
    }
    .main-footer.white-footer .rights li:last-child {
        padding-right: 0
    }
    .main-footer.white-footer .footer-soc-nets li {
        margin-bottom: 10px
    }
    /* .inner-page .top-panel-menu {
        padding-top: 10px
    } */
    .blog-page .blog-body, .blog-page .blog-link-wrapper, .blog-page header {
        padding: 0 15px
    }
    .blog-page .blog-card {
        margin-bottom: 50px
    }
}

/**------------------------------------------------category-web-start------------------------------------**/

.web-icon-container {
    height: 60px;
    width: 60px;
}

.web-categoryicon {
    height: 60px;
    width: 70px;
    background: url(../../ImagesWeb/categoryimage.png)0px 0px no-repeat;
    padding: 1px 23px 15px 30px;
    background-position: -10px -96px;
}

.auto {
    padding: 0px 10px 15px 30px;
    background-position: -10px -143px;
}

.business {
    padding: 0px 6px 15px 30px;
    background-position: -10px -345px;
}

.credit {
    padding: 0px 12px 20px 30px;
    background-position: -10px -557px;
}

.insurance {
    background-position: -10px -945px;
}

.investment {
    background-position: -10px -398px;
}

.loans {
    padding: 1px 4px 15px 30px;
    background-position: -10px -721px;
}

.mortgage {
    background-position: -10px -451px;
}

/**.personal{
       background-position: -4px -613px;
    }  **/

.retirement {
    padding: -10px 23px 20px 30px;
    background-position: -10px -10px;
}

.savings {
    padding: 0px 12px 20px 30px;
    background-position: -10px -776px;
}

.tax {
    padding: 0px 1px 20px 30px;
    background-position: -10px -294px;
}

.math {
    padding: 1px 6px 15px 30px;
    background-position: -10px -504px;
}

.conversion {
    background-position: -10px -832px;
}

.physics {
    background-position: -10px -611px;
}

.thermodynamics {
    padding: 0px 1px 15px 17px;
    background-position: -10px -888px;
}

.health {
    padding: 1px 5px 15px 30px;
    background-position: -10px -192px;
}

.lifestyle {
    padding: 1px 15px 15px 30px;
    background-position: -10px -666px;
}

.section-calculator-list-wrapper .card-icon-text p .h2style {
    color: #259984;
}

.category-page .section-calculator-list.section-cal .card-item {
    min-height: 185px;
}

.web-calculator-detail hr {
    margin-top: 0;
    margin-bottom: 0;
}

/** category-web**/

/* /Social share button / */

#share {
    width: 100%;
    text-align: center;
}

/* buttons */

#share a {
    width: 50px;
    height: 50px;
    display: inline-flex;
    margin: 3px;
    position: relative;
    border-radius: 5px;
    font-size: 25px;
    color: #fff;
    opacity: 0.75;
    transition: opacity 0.15s linear;
}

#share a:hover {
    opacity: 1;
}

/* icons */

#share i {
    position: relative;
    top: 25%;
    margin: auto;
    transform: translateY(-50%);
}

/* colors */

.facebook {
    background: #3b5998;
}

.twitter {
    background: #55acee;
}

.googleplus {
    background: #dd4b39;
}

.linkedin {
    background: #0077b5;
}

.pinterest {
    background: #cb2027;
}

.accordion .accordion-item h2.title {
    font-size: 18px;
    text-transform: inherit;
}

.accordion .accordion-item.active .panel {
    /**overflow-y:scroll;**/
    max-height: inherit;
    padding: 0 5px;
}

.accordion .accordion-item:last-child.active .panel {
    margin-bottom: 2em;
}

.faq-wrapper .accordion .accordion-item.active .panel {
    padding: 0 18px;
}

.faq-wrapper .accordion .accordion-item h2.title {
    margin: 0 0 2px;
}

.appearBut {
    border-radius: 8px;
    width: 135px;
    border: none;
    text-align: center;
    font-style: inherit;
    font-size: 15px;
    font-family: "Open Sans", sans-serif;
}

*:focus {
    outline: none;
}