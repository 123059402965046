.questions-nav {
    list-style: none;
    padding: 0;
    display: inline-block;
    border-radius: 20px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    background-color: #fff;
    margin-bottom: 13px;
    margin-top: 13px;
}

.questions-nav li {
    display: inline-block;
    cursor: pointer;
}

.questions-nav li span {
    text-decoration: none;
    padding: 8px 20px;
    margin-left: 0px;
    position: relative;
    display: block;
    text-decoration: none;
    color: black;
    text-shadow: 0px 1px 0px rgba(44, 44, 44, 0.1);
    background: #ffffff;
    height: 37px;
    font-size: 14px;
    min-width: 70px;
}

.questions-nav li span.active:after {
    border-left-color: #bfb5b5 !important;
}

.questions-nav li .active, .questions-nav li span:hover {
    background-color: #cfcfcf;
    color: #629779;
    text-shadow: 0px 1px 0px rgba(255, 255, 255, 0.5);
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2), inset 0 0 4px rgba(0, 0, 0, 0.2);
}

/* Change active item color  */
.questions-nav li:not(:last-child) span:after {
    content: " ";
    display: block;
    width: 22px;
    height: 22px;
    background-color: #ffffff;
    position: absolute;
    top: 1px;
    margin-top: 6px;
    margin-left: -11px;
    left: 100%;
    z-index: 1;
    border-top: 1px solid #cccccc;
    border-right: 1px solid #cccccc;
    border-radius: 0px 0px 0px 100px;
    transform: rotate(55deg) skewX(20deg);
}

.questions-nav li span.active::after, .questions-nav li span:hover::after {
    background-color: #cfcfcf;
    box-shadow: 0px -1px 283px rgba(0, 0, 0, 0.1);
}

.questions-nav li:first-child span {
    border-bottom-left-radius: 20px;
    border-top-left-radius: 20px;
    margin-left: 0px;
}

.questions-nav li:last-child span {
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
    padding-right: 26px;
}

.questions-nav li:last-child span:after {
    border: none;
}
.border-line{
    border-bottom: 1px solid lightgray;
}

/* Screen size till max width ==400px */

@media (max-height:812px) {
    .questions-nav li span.tab-first {
        padding: 7px 10px 7px 18px;
    }
    .questions-nav li span.tab-second {
        padding: 7px 10px 7px 25px;
    }
    .questions-nav li span.tab-third {
        padding: 7px 18px 7px 25px;
    }
}
@media (max-height:640px) {
    .questions-nav li span.tab-first {
        padding: 10px 10px 10px 15px;
        font-size: 12px;
    }
}

/* Screen size till max width ==320px */


@media (max-height:568px) {
    /* .questions-nav li span.tab-first {
        padding: 10px 10px 10px 15px;
        font-size: 12px;
    } */
    .questions-nav li span.tab-second {
        padding: 10px 10px 10px 15px;
        font-size: 12px;
    }
    .questions-nav li span.tab-third {
        padding: 10px 10px 10px 15px;
        font-size: 12px;
    }
}