/*-------------- Search Menu------------- */

input.search {
    padding: 17px 17px 18px 17px;
    width: 100%;
    height: 35px;
    padding-left: 38px;
    border-radius: 10px;
    border-bottom: 2.5px solid #3f3f41;
    color: #3C3C3C;
    background-image: url('../../Images/strip-dashboard.png');
    background-repeat: no-repeat;
    background-position: left center;
    outline: 0;
    background-size: 113px;
    background-position: 14px -69px;
    /* -webkit-box-shadow: inset 0 0 5px grey;
    box-shadow: inset 0 0 5px grey; */
}

input.search.page{
    width: 80%;
    padding-left: 40px;
    height: 10px;
    margin-left: 5px;
}



div.search-wrapper {
    margin-top: 15px;
}

/*-------------- left nav menu------------- */

.nav-background {
    background-color: rgb(92, 95, 100);
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 10px;
    height: 100vh;
    /* width: 45vh;*/
    max-width: 300px;
    z-index: 8;
    overflow: auto;
    padding-bottom: 30px;
    overflow-x: hidden;
}

.nav-background .logo {
    width: 75px;
    height: 70px;
    background: url("../../small-logo-symbol.png");
    display: block;
    background-repeat: no-repeat;
    margin-left: 15px;
}

.nav-background a {
    text-decoration: none;
    color: white;
    padding-left: 10px;
}

.nav-background .right {
    margin-left: auto;
}

span.close {
    padding: 10px;
    position: relative;
    left: -7px;
    height: 34px;
    background: url("../../Images/strip-dashboard.png");
    margin: auto;
    display: block;
    background-size: 150px;
    cursor: pointer;
    background-position: 29px -54px;
}

.header-logo {
    margin: 2px 0px 0px -5px;
    height: 50px;
    width: 50px;
    -webkit-transform: scale(.7, .7);
    transform: scale(.7, .7);
    display: inline-block;
    background: url('../../Images/sprite-min.png')0px 0px no-repeat;
    background-size: 403px 127px;
}
.navbar-left-section .search
{
    box-sizing: inherit;
}
.navbar-left-section
{
    opacity: 100;
}
.navbar-left-section .header-section{
    background-color: #2196f3;
    box-shadow: none;
}
.navbar-left-section .muitoolbar-root{
    min-height: 64px;
}
.navbar-left-section ::-webkit-input-placeholder {
    font-size: 13px;
    color: gray;
  }
.navbar-left-section ::-moz-placeholder {
    font-size: 13px;
    color: gray;
  }
.navbar-left-section :-ms-input-placeholder {
    font-size: 13px;
    color: gray;
  }
.navbar-left-section :-moz-placeholder {
    font-size: 13px;
    color: gray;
  }
/* .navbar-left-section div {
    background-color: transparent;
} */
.navbar-left-section .header-text{
    text-align: left;
    font-size: 20px;
    font-weight: bold;
    position: relative;
    top: 1px;
    left: -12px;
    font: 600 20px/16px Roboto, sans-serif;
    font-family: 'Roboto-Regular';
    cursor: pointer;
}
.navbar-left-section ul.nav-menu li{
    padding-top: 7px;
    padding-bottom: 6px;
}
.navbar-left-section .nav-background a{
    /* letter-spacing: -1.0px; */
    opacity: .90;
    font-weight: normal;
}