
ul.questions-list {
  padding: 0;
  width: 100%;
  color: black;
  font-style: normal;
  font-size: 12px;
  list-style-type: none;
  text-align: left;
}

ul.questions-list li {
  margin: 10px 8px;
}

.simple-line {
  width: 1px;
  background: lightgray;
}

ul.child li:first-child {
  border-top: 1px solid #3f3f41;
}

ul.questions-list li:last-child {
  border-bottom: none;
}

ul.questions-list.child .inline {
  padding-left: 30px;
}

.list-item-con {
  background-color: white;
  border-radius: 10px;
}

.question_content {
  width: 100%;
  height: 80px;
  display: grid;
  align-items: center;
  align-content: center;
  /* padding-left: 25px; */
  padding-top: 0px;
  padding-bottom: 4px;
}

/* this Css is used in conversation page  */
.select_input_text {
  font-size: 25px;
  font-weight: 800;
  padding: 5px;
}

.conversion_text {
  font-size: 18px;
  font-weight: 600;
  width: 100%;
}
div.right-arrow {
  margin: auto;
  margin-right: 5px;
}

img.right-arrow {
  width: 15px;
  opacity: 0.2;
}

.right-arrow-icon {
  width: 20px;
  height: 20px;
  opacity: 0.2;
  background-image: url('../../../assets/Images/fieldIcons/right_arrow.png')
}

.list_item_title {
  font-size: 16px;
  color: #717175;
}
.list_item_value {
  font-weight: 600;
  font-size: 20px;
  color: #434650;
  padding-top: 0;
  padding-left: 10px;
  width: 100%;
}

@media (min-width: 767px) {
  .list_item_title {
    font-size: 18px;
  }
  .list_item_value {
    font-size: 23px;
    padding-top: 4px;
    padding-left: 5px;
  }
}

