.chart-modal {
    display: none;
    position: fixed;
    z-index: 9999999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color:white;
}

.chart-modal .modal-content {
    width: 100%;
    border: 1px solid white;
    height: 100%;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
    overflow: auto;
    padding: 0px;
}

/* view  modal */

.chart-modal.visible {
    display: block;
    /* padding: 45px; */
    padding: 4px;
}

.chart-modal.hidden {
    display: none;
}

.chart-modal .chart-wrapper {
    padding: 10px;
}

/* The Close Button */

.chart-modal .close-icon {
    color: #bb3ca9;
    float: right;
    font-size: 30px;
    font-weight: bold;
    background-repeat: no-repeat;
    position: absolute;
    right: 13px;
    top: 6px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    padding: 0px;
    height: 33px;
    width: 33px;
    border-radius: 30px;
    z-index: 10;
    line-height: 1;
}

.chart-modal .input-keys-wrapper-postion {
    display: block;
    position: absolute;
    bottom: 0px;
    width: 100%;
    left: 0px;
    padding: 15px;
}

