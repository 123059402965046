/* The Modal (background) */
.select_list
{
    height: auto;
    width: auto;
    padding: 0 0;
    background: #fff;
    min-height: 200px;
}

.select_list_content
{
    padding: 20px;
    border: 1px solid gray;
    text-align: left;
}
.selected_item,.selected_item>div
{
    background: #2D8A5D;
    color: white;
    
}
.check_mark
{
   
    float: right;
}

.select-modal {
    display: none;
    position: fixed;
    z-index: 9999999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.8);
}

/* Modal Content */

.select-modal .modal-content {
    width: 100%;
    border: 1px solid white;
    height: 100%;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
    overflow: auto;
}

/* Title for the keyboard */

.select-modal .input-title {
    color: white;
    text-align: center;
    font: bold;
    font-size: 20px;
    margin-top: 60px;
}

/* Key input feild */

.select-modal .input-field {
    background-color: #ffffff;
    background-image: url('../../Images/griddouble.png');
    background-size: 100vw;
    background-position: center center;
    margin: 20px auto;
    padding: 30px;
    border: 3px solid #2D8A5D;
    width: 98%;
    border-radius: 10px;
    font-size: 28px;
    text-align: center;
    font-family: 'SF-Pro-Display-Bold';
    font-weight:900;
}

/* input keys wraper */
.select-modal .input-keys-wrapper-postion{

    display: block;
    position: absolute;
    bottom: 0px;
    
    width:100%;
    left:0px;
    padding: 15px;

}
.select-modal .input-keys-wrapper {
    background-image: url('../../Images/main-bg-opt.png');
    background-size: 471px 521px;
    
    background-color: #ffffff;
    margin: auto;
    padding: 10px;
    border: 3px solid #2D8A5D;
    width: 98%;
    border-radius: 10px;
    font-size: 28px;
    margin-top: 30px;
    text-align: center;

    

}

.select-modal .input-keys-table {
    width: 100%;
  
}
.select-modal .input-keys-table td{
width: 30%;
background-image:linear-gradient(#ffffff 10%, #cfcfcf );
border-radius: 10px;
margin: 2px;
padding:4px;
border: 1px solid #7f9199

}
/* .select-modal .input-keys-table button{
    touch-action: manipulation;
} */

.select-modal.hidden {
    display: none;
}

/* view  modal */

.select-modal.visible {
    display: block;
}













