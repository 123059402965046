.search-result-container {
    border: 5px solid #4bb77d;
    margin: 20px;
    border-radius: 15px;
    padding: 15px;
    background-image: url('../../Images/main-bg-opt.png');
}

.search-result-container .inner-container {
    background: white;
    border-radius: 25px;
    position: relative;
}

.inner-container .s-close-icon {
    background-image: url('../../Images/close-icon-gray.png');
    background-repeat: no-repeat;
    position: absolute;
    height: 45px;
    width: 45px;
    background-size: 20px;
    background-position: 12px 18px;
    right: 0;
}

.inner-container input {
    width: 100%;
    padding: 15px 40px;
    border-radius: 25px;
    border-top: 4px solid #BAB9BE;
    color: #3C3C3C;
    font-size: 16px;
    text-shadow: 0px 2px 2px rgba(0, 0, 0, .3);
    background-image: url('../../Images/search-icon.png');
    background-repeat: no-repeat;
    background-position: left center;
    outline: 0;
    background-size: 20px;
    background-position-x: 15px;
}

/* Search result List */

ul.search-list {
    list-style: none;
    margin: 15px;
    padding: 0;
}

ul.search-list>li {
    border-bottom: 1px solid white;
    padding: 10px;
    color: white;
}

ul.search-list li>a {
    /* text-decoration: none; */
    color: white;
    cursor: pointer;
}