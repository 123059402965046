.overlay-screen {
    position: fixed;
    display: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 2;
    cursor: pointer;
}

.overlay-screen.visible{
    display: block;
}

.overlay-screen.hidden{
    display: none;
}

.lds-dual-ring {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    display: inline-block;
    width: 85px;
    height: 85px;
  }
  .lds-dual-ring:after {
    content: " ";
    display: block;
    width: 85px;
    height: 85px;
    margin: 1px;
    border-radius: 50%;
    border: 5px solid #3CB371;
    border-color: #3CB371 transparent #3CB371 transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }